import "./SearchBar.scss";
import SearchIcon from "@mui/icons-material/Search";
import { Divider, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function SearchBar() {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    if (!query.length) return;
    navigate(`/search/${query.trim()}`);
    setQuery("");
  };

  const handleKeyPress = (e) => {
    if (!query.length) return;
    if (e.key === "Enter") {
      navigate(`/search/${query.trim()}`);
      setQuery("");
    }
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search"
        value={query}
        onKeyDown={handleKeyPress}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Divider
        sx={{
          opacity: 0.25,
          height: "70%",
          margin: "auto",
          borderColor: "rgba(0, 0, 0, 0.12)",
        }}
        orientation="vertical"
        flexItem
      />
      <IconButton onClick={handleClick}>
        <SearchIcon sx={{ color: "grey" }} />
      </IconButton>
    </div>
  );
}

export default SearchBar;
