import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemIcon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";

export default function NewCategoriesListItem({
  item,
  categoriesFilter,
  handleCheck,
  handleCheckChildren,
  filters,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (filters) {
      if (
        filters?.some((e) => e.ParentTree?.some((i) => i == item?.ID)) &&
        item?.children?.some((i) => categoriesFilter.includes(i))
      )
        setOpen(true);
    } else if (item?.children?.some((i) => categoriesFilter.includes(i)))
      setOpen(true);
  }, []);

  return (
    <List dense disablePadding>
      <ListItem
        onClick={() => setOpen((prv) => !prv)}
        key={item?._id}
        secondaryAction={
          <IconButton edge="end" aria-label="comments">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          disabled={
            filters
              ? !filters?.some((e) => e.ParentTree?.some((i) => i == item?.ID))
              : false
          }
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              tabIndex={-1}
              indeterminate={
                item?.children?.some((i) => categoriesFilter.includes(i)) &&
                !item?.children?.every((i) => categoriesFilter.includes(i))
              }
              checked={item?.children?.every((i) =>
                categoriesFilter.includes(i)
              )}
              onClick={(e) => handleCheckChildren(e, item)}
              disableRipple
              inputProps={{ "aria-labelledby": item?.ID }}
            />
          </ListItemIcon>
          <ListItemText
            id={item?.DisplayName}
            primary={item?.DisplayName}
            primaryTypographyProps={{ fontSize: "14px" }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {item?.children?.map((value) => (
          <ListItem key={value?.ID}>
            <ListItemButton
              disabled={
                filters
                  ? !filters?.some((e) =>
                      e.ParentTree?.some((i) => i == value?.ID)
                    )
                  : false
              }
              dense
              onClick={() => handleCheck(value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={categoriesFilter.includes(value)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                id={value?.ID}
                primary={value?.DisplayName}
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
}
