import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  groups: [],
  media: [],
  filterBy: "Bank",
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    saveFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    saveMedia(state, action) {
      state.media = action.payload;
    },
    saveCategories(state, action) {
      state.categories = action.payload;
    },
    saveGroups(state, action) {
      state.groups = action.payload;
    },
    flashMedia(state, action) {
      state.media = [];
    },
    flashGroups(state, action) {
      state.groups = [];
    },
    flashCategories(state, action) {
      state.categories = [];
    },
    flashFilters(state, action) {
      state.categories = [];
      state.groups = [];
      state.media = [];
      state.filterBy = "Bank";
    },
  },
});

export const {
  saveFilterBy,
  saveMedia,
  saveCategories,
  saveGroups,
  flashCategories,
  flashGroups,
  flashMedia,
  flashFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
