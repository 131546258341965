import { Outlet, ScrollRestoration } from "react-router-dom";

import { MainContainer } from "../../Theme/styled";
import Navbar from "../Components/Navbar/Navbar";

export default function MainLayout() {
  const containerWidth = "false"; // sx on mainContainer is hardcoded at 85%

  return (
    <>
      <ScrollRestoration />
      <Navbar containerWidth={containerWidth} />
      <MainContainer
        sx={{ paddingTop: 8, paddingBottom: 2 }}
        maxWidth={containerWidth}
      >
        <Outlet />
      </MainContainer>
    </>
  );
}
