import { useEffect, useRef, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, IconButton, Typography } from "@mui/material";

import useOutsideAlerter from "../../../Hooks/useOutSideAlerter";

import "./PlaylistsOptions.scss";

export default function PlaylistsOptions({
  setDeleteSelectedModal,
  setDeleteAllModal,
  setSelectionMode,
  selectionMode,
  setSelection,
  selection,
  hideOptions,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelectionMode(false);
      setSelection([]);
    }
  }, [open]);

  const ref = useRef();

  useOutsideAlerter(
    ref,
    () => {
      setOpen(false);
    },
    selectionMode
  );

  return (
    <Box p={1} pr={0} pb={0} className="playlistsOptions">
      <Box ref={ref} sx={{ display: "flex" }}>
        <Box pr={1} className={open ? "openOptions" : "closeOptions"}>
          {selection?.length > 0 && (
            <Button
              onClick={() => setDeleteSelectedModal(true)}
              color="primary"
              sx={{ textTransform: "unset" }}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={() => {
              setSelectionMode((prv) => !prv);
              setSelection([]);
            }}
            sx={{ textTransform: "unset" }}
          >
            {selectionMode ? "Cancel" : "Select"}
          </Button>
        </Box>
        {!hideOptions && (
          <IconButton size="small" onClick={() => setOpen((prv) => !prv)}>
            <MoreVertIcon />
          </IconButton>
        )}
        <IconButton size="small" onClick={() => setDeleteAllModal(true)}>
          <DeleteForeverIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
}
