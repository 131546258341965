import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPlaylists = createAsyncThunk(
  "playlists/fetchPlaylists",
  async (_, { getState }) => {
    const { user } = getState();

    const response = await axios(
      `${process.env.REACT_APP_SERVER_URL}/api/play/playlists/org`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          bearer: user.token,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  lastFetchTime: null,
};

const playlistsSlice = createSlice({
  name: "playlists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaylists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPlaylists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchPlaylists.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { storeData } = playlistsSlice.actions;

export default playlistsSlice.reducer;
