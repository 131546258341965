import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const TruncatedTextWithTooltip = ({
  fontSize,
  text,
  maxLength,
  variant,
  color,
  fontWeight,
}) => {
  const truncatedText =
    text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <Tooltip title={text} arrow>
      <Typography
        fontSize={fontSize}
        variant={variant}
        noWrap
        color={color}
        fontWeight={fontWeight}
      >
        {truncatedText}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
