import NewCategoriesListItem from "./NewCategoriesListItem";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function NewCategoriesList({
  categoriesFilter,
  setCategoriesFilter,
  data,
  filters,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleCheckChildren = (e, item) => {
    e.stopPropagation();
    const newChecked = [...categoriesFilter];
    let flag = true;
    if (item?.children.every((i) => newChecked.includes(i))) flag = false;
    if (filters && item?.children.some((i) => newChecked.includes(i)))
      flag = false;

    item?.children?.forEach((i) => {
      const currentIndex = newChecked.indexOf(i);
      if (currentIndex !== -1) newChecked.splice(currentIndex, 1);
    });

    if (filters && flag) {
      item?.children?.forEach((i) => {
        if (filters?.some((e) => e.ParentTree.some((j) => j == i.ID)))
          newChecked.push(i);
      });
      return dispatch(setCategoriesFilter(newChecked));
    }

    if (flag) item?.children?.forEach((i) => newChecked.push(i));
    dispatch(setCategoriesFilter(newChecked));
  };

  const handleCheck = (value) => {
    const newChecked = [...categoriesFilter];
    const currentIndex = newChecked.indexOf(value);
    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    dispatch(setCategoriesFilter(newChecked));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {data && (
        <List dense disablePadding>
          <ListItem
            onClick={() => setOpen((prv) => !prv)}
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton>
              {/* <CategoryIcon /> */}
              <BackupTableIcon />
              <ListItemText
                primary="Categories"
                primaryTypographyProps={{
                  fontSize: "19px",
                  fontWeight: "bold",
                  marginLeft: 1.5,
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        {data?.map((item) => (
          <NewCategoriesListItem
            key={item._id}
            item={item}
            categoriesFilter={categoriesFilter}
            handleCheckChildren={handleCheckChildren}
            handleCheck={handleCheck}
            filters={filters}
          />
        ))}
      </Collapse>
    </Box>
  );
}
