import useAxios from "../Hooks/useAxios";
import MiniFooter from "../Layouts/Components/MiniFooter";
import PlaylistFilterList from "../Layouts/Components/Playlists/PlaylistFilterList";
import PlaylistsOptions from "../Layouts/Components/PlaylistsOptions/PlaylistsOptions";
import WarningModal from "../Layouts/Components/WarningModal/WarningModal";
import MiniPlayer from "../Layouts/Components/video/miniPlayer/MiniPlayer";
import { fetchPlaylists } from "../redux/playlistsSlice";
import { Box, Card, Checkbox, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function Playlist() {
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedList, setSelectedList] = useState(id);
  const [videoData, setVideoData] = useState([]);
  const [videoLoading, setVideoLoading] = useState(true);
  const [videoError, setVideoError] = useState(null);
  const [open, setOpen] = useState(false); // warning modal handler
  const [open1, setOpen1] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [selection, setSelection] = useState([]);
  const dispatch = useDispatch();

  const handleSelection = (video) => {
    let temp = [...selection];
    if (selection?.includes(video)) temp = temp.filter((i) => i != video);
    else temp.push(video);
    console.log(temp);
    setSelection(temp);
  };

  const { data, error, isLoading, force } = useAxios(
    "/api/play/playlists/org",
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: user.token,
        "Content-Type": "application/json",
      },
    }
  );

  const fetchVideo = async () => {
    setVideoLoading(true);
    try {
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/playlists`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            playlistId: selectedList,
          },
        }
      );
      setVideoData(response.data);
      setVideoError(null);
    } catch (error) {
      console.log(error);
      setVideoError(error);
      setVideoData([]);
    } finally {
      setVideoLoading(false);
    }
  };

  const deletePlaylist = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/playlist`,
        {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            playlistId: id,
          },
        }
      );
      setOpen(false);
      dispatch(fetchPlaylists());
      navigate("/playlists");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSelected = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/playlist/selected`,
        {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            playlistId: id,
            ids: selection.map((i) => i?._id),
          },
        }
      );
      setOpen1(false);
      fetchVideo();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVideo();
  }, [selectedList]);

  if (error)
    return (
      <Typography>Something went wrong while loading the video.</Typography>
    );

  return (
    <>
      <WarningModal
        callback={deletePlaylist}
        open={open}
        setOpen={setOpen}
        title="Are you sure you want to delete this playlist?"
        message="This action can not be undone."
      />
      <WarningModal
        callback={deleteSelected}
        open={open1}
        setOpen={setOpen1}
        title="Are you sure you want to delete these videos?"
        message="This action can not be undone."
      />
      {!isLoading && !videoLoading ? (
        <Box className="videoGrid" sx={{ height: "100%" }}>
          <div sx={{ height: "100%", overflow: "scroll" }}>
            <Box
              sx={{
                position: "fixed",
                width: "350px",
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
                justifyContent: "space-between",
                height: "94vh",
              }}
            >
              <PlaylistFilterList
                data={data}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
              <MiniFooter />
            </Box>
          </div>
          <div>
            {videoError?.response?.status === 404 && (
              <Typography p={2}>Playlist not found.</Typography>
            )}
            {videoData?.message === "Empty" && videoData?.isEditableByUser && (
              <PlaylistsOptions
                selectionMode={selectionMode}
                setSelectionMode={setSelectionMode}
                setDeleteAllModal={setOpen}
                setDeleteSelectedModal={setOpen1}
                setSelection={setSelection}
                selection={selection}
                hideOptions
              />
            )}
            {videoData?.message === "Empty" && (
              <Typography p={2}>This playlist is empty.</Typography>
            )}
            {videoData[0]?.isEditableByUser && (
              <PlaylistsOptions
                selectionMode={selectionMode}
                setSelectionMode={setSelectionMode}
                setDeleteAllModal={setOpen}
                setDeleteSelectedModal={setOpen1}
                setSelection={setSelection}
                selection={selection}
              />
            )}
            <div
              className="miniPlayerContainer"
              style={{ paddingBottom: "2em", paddingTop: "1em" }}
            >
              {videoData[0]?.elements?.map((video) => (
                <Card
                  key={video?._id}
                  style={{
                    transition: "all 0.3s ease-out",
                    position: "relative",
                  }}
                  sx={selection?.includes(video) ? { opacity: "50%" } : null}
                  onClick={selectionMode ? () => handleSelection(video) : null}
                >
                  <MiniPlayer
                    disabled={selectionMode}
                    avatar={video?.node[0]?.ImageURL}
                    url={video?.node[0]?.element[0]?.Path}
                    BankId={video?.BankID}
                    id={video?.NodeID}
                    media={video?.MediaID}
                    BankName={video?.node[0]?.BankName}
                    DisplayName={video?.node[0]?.DisplayName}
                    imgClassName={
                      video?.MediaID === "16"
                        ? "web-img"
                        : video?.MediaID === "22" || video?.MediaID === "23"
                        ? "ios-img"
                        : video?.MediaID === "27"
                        ? "watch-img"
                        : ""
                    }
                  />

                  {selection.includes(video) && (
                    <Checkbox
                      disableRipple
                      checked
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 99,
                      }}
                    />
                  )}
                </Card>
              ))}
            </div>
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
