import { Box, Link, Typography } from "@mui/material";

import { AppFooter, MainContainer } from "../../Theme/styled";

export default function Footer({ containerWidth }) {
  return (
    <AppFooter>
      <MainContainer maxWidth={containerWidth}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            All rights reserved - {new Date().getFullYear()} Scientia FinTech
            Insights Ltd. &copy;
          </Typography>
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <Link
              sx={{ color: "inherit" }}
              href="https://www.fintechinsights.io/privacy-policy?hsLang=en"
              target="_blank"
              underline="none"
            >
              <Typography>Privacy Policy</Typography>
            </Link>

            <Link
              sx={{ color: "inherit" }}
              href="https://www.fintechinsights.io/terms-and-conditions?hsLang=en"
              target="_blank"
              underline="none"
            >
              <Typography>Terms & Conditions</Typography>
            </Link>
          </Box>
        </Box>
      </MainContainer>
    </AppFooter>
  );
}
