import PlaylistFilterListItem from "./PlaylistFilterListItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";

export default function PlaylistFilterList({
  data,
  selectedList,
  setSelectedList,
  collapsible,
}) {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {!collapsible ? (
        <>
          {data && (
            <Box
              pt={2}
              pl={1}
              sx={{ display: "flex", gap: 1.5, alignItems: "center" }}
            >
              <ListIcon />
              <Typography variant="h4">Playlists</Typography>
            </Box>
          )}
          <Box>
            {data?.map((item, index) => (
              <PlaylistFilterListItem
                index={index}
                key={item?.user?._id}
                item={item}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
            ))}
          </Box>
        </>
      ) : (
        <>
          {data && (
            <List dense disablePadding>
              <ListItem
                onClick={() => setOpen((prv) => !prv)}
                secondaryAction={
                  <IconButton edge="end" aria-label="comments">
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton>
                  <ListIcon />
                  <ListItemText
                    primary="Playlists"
                    primaryTypographyProps={{
                      fontSize: "19px",
                      fontWeight: "bold",
                      marginLeft: 1.5,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}
          <Collapse in={open} timeout="auto" unmountOnExit>
            {data?.map((item, index) => (
              <PlaylistFilterListItem
                index={index}
                key={item?.user?._id}
                item={item}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
            ))}
          </Collapse>
        </>
      )}
    </Box>
  );
}
