import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export default function PlaylistFilterListItem({
  item,
  index,
  selectedList,
  setSelectedList,
}) {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      item?.playlists?.some((i) => i?._id == selectedList) ||
      (index === 0 && !id)
    )
      setOpen(true);
  }, []);

  return (
    <List dense>
      <ListItemButton onClick={() => setOpen((prv) => !prv)} key={item?._id}>
        <ListItemText
          id={item?.user?._id}
          primary={
            index === 0 && item?.isEditableByUser
              ? "My Playlists"
              : `${item?.user?.firstName} ${item?.user?.lastName}`
          }
        />
        <IconButton edge="end" aria-label="comments">
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {item?.playlists?.map((value) => (
          <ListItem key={value?._id}>
            <ListItemButton
              onClick={() => {
                setSelectedList(value?._id);
                navigate(`/playlist/${value?._id}`);
              }}
            >
              <ListItemText
                id={value?._id}
                primary={value?.playlistName}
                sx={selectedList == value?._id ? { color: "#4a59ff" } : null}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
}
