import Player from "../Layouts/Components/video/player/Player";
import Home from "../Pages/Home";
import Playlist from "../Pages/Playlist";
import Playlists from "../Pages/Playlists";
import Search from "../Pages/Search";
import { Navigate } from "react-router-dom";

export const userRoutes = [
  {
    path: "/",
    name: "hiddenFromNavbar",
    element: <Home />,
  },

  {
    path: "search/:query",
    name: "hiddenFromNavbar",
    element: <Search />,
  },

  {
    path: "play/:bank/:media/:id",
    name: "hiddenFromNavbar",
    element: <Player />,
  },

  {
    path: "playlists",
    name: "hiddenFromNavbar",
    element: <Playlists />,
  },
  {
    path: "playlist/:id",
    name: "hiddenFromNavbar",
    element: <Playlist />,
  },

  {
    path: "*",
    name: "hiddenFromNavbar",
    element: <Navigate to="/" />,
  },
];
