export const lightTheme = {
  palette: {
    mode: "light",
    primary: {
      main: "#4a59ff",
    },
    secondary: {
      main: "#808080",
    },
    info: {
      main: "#FCDCE4",
    },
    background: {
      default: "#eef0f4",
    },
  },
  typography: {
    fontFamily: "Lexend Deca",
    fontSize: 14,
    h1: {
      fontSize: 50,
      fontWeight: 600,
    },
    h2: {
      fontSize: 40,
      fontWeight: 600,
    },
    h3: {
      fontSize: 35,
      fontWeight: 600,
    },
    h4: {
      fontSize: 19,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    body: {
      fontSize: 14,
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#4a59ff",
    },
    secondary: {
      main: "#B5BDCF",
    },
    info: {
      main: "#FCDCE4",
    },
  },
  typography: {
    fontFamily: "Lexend Deca",
    fontSize: 14,
    h1: {
      fontSize: 50,
      fontWeight: 600,
    },
    h2: {
      fontSize: 40,
      fontWeight: 600,
    },
    h3: {
      fontSize: 35,
      fontWeight: 600,
    },
    h4: {
      fontSize: 19,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    body: {
      fontSize: 14,
    },
  },
};
