import PrimaryButton from "../PrimaryButton/PrimaryButton";
import Timer from "../Timer/Timer";
import "./style.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useEffect, useState } from "react";

const style = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  outline: "none",
  width: 500,
  borderRadius: "10px",
  position: "absolute",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function DownloadModal({
  user,
  open,
  setOpen,
  downloadLimit,
  setDownloadLimit,
  downloadAction,
  setDownloadAction,
  url,
  checked,
}) {
  const [waitingForPin, setWaitingForPin] = useState(false);
  const [timer, setTimer] = useState(30);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [pinError, setPinError] = useState("");
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  // timeout to fix the issue of auto scroll (trying to focus on an element that it does not exist)
  const handleClose = () => {
    document.body.focus();

    setTimeout(() => {
      setPinError("");
      setPin1("");
      setPin2("");
      setPin3("");
      setPin4("");
      setPin5("");
      setPin6("");
      setDownloadAction("");
      setWaitingForPin(false);
      setTimer(30);
      setDownloadSuccess(false);
      setOpen(false);
    }, 0);
  };

  //   screenAll;
  const downloadAll = () => {
    const downloadUrl = `https://media.fintechinsights.io/snapshot.php?id=${encodeURIComponent(
      url
    )}`;
    location.href = downloadUrl;
    upsert(downloadUrl);
  };

  //   gifAll
  const handleDownload = async () => {
    const downloadUrl = `https://media.fintechinsights.io/gif_download.php?id=${encodeURIComponent(
      url
    ).replace(".mp4", "")}`;
    try {
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const blobURL = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobURL;
      link.download = "image.gif";
      link.click();

      URL.revokeObjectURL(blobURL);
      upsert(downloadUrl);
    } catch (error) {
      console.error(error);
    }
  };

  //   selectedS
  const handleSelectedImagesS = async () => {
    let downloadUrl = `https://media-cdn.fintechinsights.io/download.php?id=${encodeURIComponent(
      url
    ).replace(".mp4", "")}`;
    let elements = [];
    checked.forEach((element) => (elements += `${element + 1},`));
    elements = elements.slice(0, -1);
    downloadUrl += `&elements=${elements}`;
    window.open(downloadUrl);
    upsert(downloadUrl);
  };

  //   selectedG
  const handleSelectedImagesG = async () => {
    let downloadUrl = `https://media-cdn.fintechinsights.io/convert.php?id=${encodeURIComponent(
      url
    ).replace(".mp4", "")}`;
    let elements = [];
    checked.forEach((element) => (elements += `${element + 1},`));
    elements = elements.slice(0, -1);
    downloadUrl += `&elements=${elements}`;
    console.log(downloadUrl);
    window.open(downloadUrl);
    upsert(downloadUrl);
  };

  // adds the user to the downloads collection
  // after a successful download
  // also updates the download limit
  const upsert = async (url) => {
    try {
      const metadataResponse = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/download/upsert`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            url,
          },
        }
      );

      const resp = metadataResponse.data;
      if (metadataResponse.status === 200) setDownloadLimit(resp.limit);
    } catch (error) {
      console.error(error);
    }
  };

  const createPin = async () => {
    try {
      const metadataResponse = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/download/pin/create`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (metadataResponse.status === 200) setWaitingForPin(true);
    } catch (error) {
      setPinError("Something went wrong. Please try again.");
    }
  };

  const verifyPin = async () => {
    try {
      const metadataResponse = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/download/pin/verify`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            pin: `${pin1}${pin2}${pin3}${pin4}${pin5}${pin6}`,
          },
        }
      );
      if (metadataResponse.status === 200) {
        if (downloadAction === "screenAll") downloadAll();
        else if (downloadAction === "gifAll") handleDownload();
        else if (downloadAction === "selectedS") handleSelectedImagesS();
        else if (downloadAction === "selectedG") handleSelectedImagesG();
        setDownloadSuccess(true);
        setDownloadLimit((s) => s - 1);
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    } catch (error) {
      setPinError("Invalid PIN");
    }
  };

  const resend = async () => {
    try {
      const metadataResponse = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/download/pin/resend`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (metadataResponse.status === 200) setTimer(30);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePinInput = (e, where) => {
    const re = /^[0-9\b]+$/; // regular expression to allow only numbers
    if (re.test(e.target.value)) {
      where === 1
        ? setPin1(e.target.value)
        : where === 2
        ? setPin2(e.target.value)
        : where === 3
        ? setPin3(e.target.value)
        : where === 4
        ? setPin4(e.target.value)
        : where === 5
        ? setPin5(e.target.value)
        : setPin6(e.target.value);

      where === 1
        ? document.getElementById("pin2").focus()
        : where === 2
        ? document.getElementById("pin3").focus()
        : where === 3
        ? document.getElementById("pin4").focus()
        : where === 4
        ? document.getElementById("pin5").focus()
        : where === 5
        ? document.getElementById("pin6").focus()
        : document.getElementById("pin6").blur();
    }
  };

  const handleCapture = (e, where) => {
    if (e.code === "Backspace") {
      if (where === 1) {
        setPin1("");
      } else if (where === 2) {
        if (pin2?.length) setPin2("");
        else {
          setPin1("");
          document.getElementById("pin1").focus();
        }
      } else if (where === 3) {
        if (pin3?.length) setPin3("");
        else {
          setPin2("");
          document.getElementById("pin2").focus();
        }
      } else if (where === 4) {
        if (pin4?.length) setPin4("");
        else {
          setPin3("");
          document.getElementById("pin3").focus();
        }
      } else if (where === 5) {
        if (pin5?.length) setPin5("");
        else {
          setPin4("");
          document.getElementById("pin4").focus();
        }
      } else if (where === 6) {
        if (pin6?.length) setPin6("");
        else {
          setPin5("");
          document.getElementById("pin5").focus();
        }
      }
    }
    if (e.code === "ArrowRight") {
      if (where === 1) {
        document.getElementById("pin2").focus();
      } else if (where === 2) {
        document.getElementById("pin3").focus();
      } else if (where === 3) {
        document.getElementById("pin4").focus();
      } else if (where === 4) {
        document.getElementById("pin5").focus();
      } else if (where === 5) {
        document.getElementById("pin6").focus();
      }
    }

    if (e.code === "ArrowLeft") {
      if (where === 2) {
        document.getElementById("pin1").focus();
      } else if (where === 3) {
        document.getElementById("pin2").focus();
      } else if (where === 4) {
        document.getElementById("pin3").focus();
      } else if (where === 5) {
        document.getElementById("pin4").focus();
      } else if (where === 6) {
        document.getElementById("pin5").focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e?.clipboardData?.getData("text");
    if (paste?.length === 6) {
      setPin1(paste[0]);
      setPin2(paste[1]);
      setPin3(paste[2]);
      setPin4(paste[3]);
      setPin5(paste[4]);
      setPin6(paste[5]);

      document.getElementById("pin1").blur();
      document.getElementById("pin2").blur();
      document.getElementById("pin3").blur();
      document.getElementById("pin4").blur();
      document.getElementById("pin5").blur();
      document.getElementById("pin6").blur();
    }
  };

  useEffect(() => {
    setPinError("");
    if (
      pin1?.length &&
      pin2?.length &&
      pin3?.length &&
      pin4?.length &&
      pin5?.length &&
      pin6?.length
    )
      verifyPin();
  }, [pin1, pin2, pin3, pin4, pin5, pin6]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ outline: "none" }}
      disableEnforceFocus
      BackdropProps={{ style: { backgroundColor: "#000000A3" } }}
    >
      <Box sx={style} className="downloadModal">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
        >
          <IconButton>
            <CloseIcon fontSize="medium" onClick={handleClose} />
          </IconButton>
        </Box>

        {downloadSuccess ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              flexDirection: "column",
              height: "7em",
            }}
          >
            <Box>
              <Typography variant="h5" pb={1}>
                Download{" "}
                {downloadAction === "screenAll" ||
                downloadAction === "selectedS"
                  ? "Screenshots"
                  : "GIF"}
              </Typography>
              <Typography>
                Your download will begin shorty, this pop-up will close
                automatically
              </Typography>
            </Box>
            <Typography fontWeight="bold">
              Remaining Daily Download Limit: {downloadLimit}
            </Typography>
          </Box>
        ) : (
          <>
            {downloadLimit < 1 ? (
              <Box pb={2}>
                <Typography variant="h5" pb={1}>
                  Download{" "}
                  {downloadAction === "screenAll" ||
                  downloadAction === "selectedS"
                    ? "Screenshots"
                    : "GIF"}
                </Typography>
                <Typography color="inherit">
                  You have reached your daily limit to download
                  screenshots/videos.{" "}
                  <a
                    href="https://meetings.hubspot.com/n-belesis/upgrade-your-fi-license"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact sales for assistance.
                  </a>
                </Typography>
              </Box>
            ) : (
              <Box>
                <Box pb={2}>
                  <Typography variant="h5" pb={1}>
                    Download{" "}
                    {downloadAction === "screenAll" ||
                    downloadAction === "selectedS"
                      ? "Screenshots"
                      : "GIF"}
                  </Typography>
                  <Typography>
                    {!waitingForPin
                      ? "You have requested to download a set of screenshots/video. To do this please accept our Terms and Conditions."
                      : "You have requested to download a set of screenshots/video. To do this please type the 6-digit PIN we have sent to your email."}
                  </Typography>
                </Box>
                {waitingForPin ? (
                  <Box>
                    {/* labels (pinError) */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        pb: 1,
                        pt: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "14px !important" }}>
                        Enter PIN code
                      </Typography>
                      {pinError && (
                        <Typography
                          sx={{ fontSize: "14px !important" }}
                          style={{ color: "orange" }}
                        >
                          Wrong PIN
                        </Typography>
                      )}
                    </Box>
                    <div className="flex-table form-input">
                      {/* inputs */}
                      <div className="flex-row verification-grid">
                        <input
                          className="form-input-number"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          onPaste={handlePaste}
                          onInput={(e) => handlePinInput(e, 1)}
                          onKeyDownCapture={(e) => handleCapture(e, 1)}
                          maxLength="1"
                          autoFocus
                          value={pin1}
                          id="pin1"
                        />
                        <input
                          className="form-input-number"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          onPaste={handlePaste}
                          onInput={(e) => handlePinInput(e, 2)}
                          onKeyDownCapture={(e) => handleCapture(e, 2)}
                          maxLength="1"
                          value={pin2}
                          id="pin2"
                        />
                        <input
                          className="form-input-number"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          onPaste={handlePaste}
                          onInput={(e) => handlePinInput(e, 3)}
                          onKeyDownCapture={(e) => handleCapture(e, 3)}
                          maxLength="1"
                          value={pin3}
                          id="pin3"
                        />
                        <input
                          className="form-input-number"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          onPaste={handlePaste}
                          onInput={(e) => handlePinInput(e, 4)}
                          onKeyDownCapture={(e) => handleCapture(e, 4)}
                          maxLength="1"
                          value={pin4}
                          id="pin4"
                        />
                        <input
                          className="form-input-number"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          onPaste={handlePaste}
                          onInput={(e) => handlePinInput(e, 5)}
                          onKeyDownCapture={(e) => handleCapture(e, 5)}
                          maxLength="1"
                          value={pin5}
                          id="pin5"
                        />
                        <input
                          className="form-input-number"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          onPaste={handlePaste}
                          onInput={(e) => handlePinInput(e, 6)}
                          onKeyDownCapture={(e) => handleCapture(e, 6)}
                          maxLength="1"
                          value={pin6}
                          id="pin6"
                        />
                      </div>
                    </div>

                    {/* resend pin timer-button */}
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {timer > 0 ? (
                        <Typography
                          sx={{ fontSize: "14px !important" }}
                          className="resend-pin-text"
                        >
                          Resend PIN in: <b>0:</b>
                          <Timer timer={timer} setTimer={setTimer} />
                        </Typography>
                      ) : (
                        <Typography
                          className="resend-pin-text"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontSize: "14px !important",
                          }}
                          onClick={resend}
                        >
                          Resend PIN
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                  >
                    <Box
                      sx={{
                        maxHeight: "12em",
                        overflow: "auto",
                        border: "1px solid #4a59ff",
                        borderRadius: "5px",
                        p: 1,
                      }}
                    >
                      <Typography textAlign="justify">
                        By downloading any content from FinTech Insights, you
                        agree to be bound by the following terms and conditions
                        (T&Cs):
                        <br />
                        <br />
                        <b>Content Ownership</b>
                        <br />
                        The images and videos featured on FinTech Insights
                        demonstrate the functionalities and user journeys of
                        financial institutions&apos; alternative banking
                        channels. The recorded content is owned by the
                        respective institutions depicted. Downloading this
                        content does not transfer ownership, usage rights, or
                        copyright to you.
                        <br />
                        <br />
                        <b>Permitted Use</b>
                        <br />
                        Access to FinTech Insights is provided through an
                        organizational license agreement. All users must be
                        active employees of the licensed organization. You may
                        access and download FinTech Insights content solely for
                        internal business purposes related to your work for the
                        licensed organization. You may not sell, share or
                        otherwise profit from the content. User credentials may
                        not be shared with anyone outside the organization. The
                        licensed organization maintains the right to enable or
                        disable access for their employees.
                        <br />
                        <br />
                        <b>Prohibited Use</b>
                        <br />
                        You may not provide FinTech Insights credentials or
                        content to anyone outside your organization or use the
                        materials for any personal purpose unrelated to work.
                        Doing so breaches the company agreement. It may also
                        violate state and federal laws. Violators may face civil
                        litigation or criminal prosecution.
                        <br />
                        <br />
                        <b>Confidentiality</b>
                        <br />
                        FinTech Insights content contains sensitive proprietary
                        information. You must keep materials confidential per
                        your organization&apos;s non-disclosure agreement. Tell
                        us immediately if any materials are used improperly so
                        we can take swift legal action.
                        <br />
                        <br />
                        <b>No Endorsement/Disclaimer of Warranties</b>
                        <br />
                        Content does not imply endorsement of any depicted
                        institution, product or service. All materials are
                        provided “as is” without warranties of any kind.
                        <br />
                        <br />
                        <b>Changes to Terms</b>
                        <br />
                        We may update these terms periodically without notice.
                        Continued use indicates acceptance of updated terms, so
                        please check back regularly.
                        <br />
                        <br />
                        <b>Termination Rights</b>
                        <br />
                        We reserve the right to terminate any user account or
                        restrict/suspend/limit platform access without notice
                        for Terms violations and/or license breaches by licensee
                        organizations.
                      </Typography>
                    </Box>
                    <PrimaryButton fullWidth onClick={createPin}>
                      Accept Terms and Conditions
                    </PrimaryButton>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}
