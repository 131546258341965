import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import {
  flashCategories,
  flashMedia,
  saveCategories,
  saveGroups,
  saveMedia,
} from "../../../redux/filtersSlice";
import { StyledBreadCrumbs } from "../../../Theme/styled";

export default function CustomSeparatorBreadCrumbs({
  bank,
  category,
  groupsData,
  categoriesData,
  media,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [categoryPath, setCategoryPath] = useState([]);

  // category path
  const findPath = (obj, targetDisplayName, path = []) => {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = findPath(obj[i], targetDisplayName, path);
        if (result) {
          return result;
        }
      }
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.DisplayName === targetDisplayName) {
        return [...path, obj];
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && typeof obj[key] === "object") {
          const result = findPath(obj[key], targetDisplayName, [...path, obj]);
          if (result) {
            return result;
          }
        }
      }
    }
  };

  const handleGroupClick = () => {
    for (const i of groupsData) {
      for (const e of i.banks) {
        if (e.BankName == bank) {
          dispatch(saveGroups([e]));
          break;
        }
      }
    }
    dispatch(flashCategories());
    dispatch(flashMedia());
    navigate("/");
  };

  const handleCategoryClick = (item, firstLevel) => {
    if (firstLevel) {
      const newChecked = [];
      item?.children?.forEach((i) => newChecked.push(i));
      dispatch(saveCategories(newChecked));
    } else dispatch(saveCategories([item]));

    // to save media
    if (media == "16") dispatch(saveMedia(["Web"]));
    else if (media == "27") dispatch(saveMedia(["Watch"]));
    else dispatch(saveMedia(["iOS"]));

    // to save bank
    for (const i of groupsData) {
      for (const e of i.banks) {
        if (e.BankName == bank) {
          dispatch(saveGroups([e]));
          break;
        }
      }
    }
    navigate("/");
  };

  const handleMediaClick = () => {
    if (media == "16") dispatch(saveMedia(["Web"]));
    else if (media == "27") dispatch(saveMedia(["Watch"]));
    else dispatch(saveMedia(["iOS"]));

    // to save bank
    for (const i of groupsData) {
      for (const e of i.banks) {
        if (e.BankName == bank) {
          dispatch(saveGroups([e]));
          break;
        }
      }
    }
    dispatch(flashCategories());
    navigate("/");
  };

  useEffect(() => {
    if (!categoriesData || !category) return;
    const path = findPath(categoriesData, category);
    setCategoryPath(path);
  }, [categoriesData, category]);

  const getLinkPaths = () =>
    categoryPath?.map((i, index) => {
      if (index == 0)
        return (
          <Link
            key={3 + index}
            underline="hover"
            sx={
              theme?.palette?.mode == "dark"
                ? { cursor: "pointer", color: "white" }
                : { cursor: "pointer" }
            }
            onClick={() => handleCategoryClick(i, true)}
          >
            {i.DisplayName}
          </Link>
        );
      if (index == 1)
        return (
          <Link
            key={3 + index}
            underline="hover"
            sx={
              theme?.palette?.mode == "dark"
                ? { cursor: "pointer", color: "white" }
                : { cursor: "pointer" }
            }
            onClick={() => handleCategoryClick(i, false)}
          >
            {i.DisplayName}
          </Link>
        );
    });

  const breadcrumbs = [
    <Link
      key="1"
      underline="hover"
      sx={
        theme?.palette?.mode == "dark"
          ? { cursor: "pointer", color: "white" }
          : { cursor: "pointer" }
      }
      onClick={() => navigate("/")}
      color="primary"
    >
      Home
    </Link>,
    <Link
      key="2"
      underline="hover"
      sx={
        theme?.palette?.mode == "dark"
          ? { cursor: "pointer", color: "white" }
          : { cursor: "pointer" }
      }
      onClick={handleGroupClick}
    >
      {bank}
    </Link>,
    <Link
      key="3"
      underline="hover"
      sx={
        theme?.palette?.mode == "dark"
          ? { cursor: "pointer", color: "white" }
          : { cursor: "pointer" }
      }
      onClick={handleMediaClick}
    >
      {media == "16" ? "Web" : media == "27" ? "Watch" : "iOS"}
    </Link>,
    getLinkPaths(),
    <Typography key="100" underline="hover">
      {category}
    </Typography>,
  ];

  return (
    <StyledBreadCrumbs
    //  separator={<NavigateNextIcon fontSize="small" />}
    >
      {breadcrumbs}
    </StyledBreadCrumbs>
  );
}
