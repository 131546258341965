import CustomModalImage from "./CustomModalImage";
import DownloadModal from "./DownloadModal/DownloadModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function ScreenShots({ duration, returnImage, media, url }) {
  const [checked, setChecked] = useState([]);

  // download modal state
  const [open, setOpen] = useState(false);
  const [downloadAction, setDownloadAction] = useState("");
  const [downloadLimit, setDownloadLimit] = useState(null);

  const [lockPopup, setLockPopup] = useState(true);

  const user = useSelector((state) => state.user);

  const handleCheck = (e, index) => {
    let temp = [...checked];
    if (temp.includes(index)) temp = temp.filter((i) => i !== index);
    else temp.push(index);
    setChecked(temp);
  };

  useEffect(() => {
    (async () => {
      let apiURL = process.env.REACT_APP_SERVER_URL;
      apiURL += "/api/download";
      try {
        const metadataResponse = await axios(
          `${process.env.REACT_APP_SERVER_URL}/api/download`,
          {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
              bearer: user.token,
              "Content-Type": "application/json",
            },
          }
        );
        const resp = metadataResponse.data;
        setDownloadLimit(resp.limit);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Box mt={5}>
      <DownloadModal
        user={user}
        open={open}
        setOpen={setOpen}
        downloadAction={downloadAction}
        setDownloadAction={setDownloadAction}
        downloadLimit={downloadLimit}
        setDownloadLimit={setDownloadLimit}
        url={url}
        checked={checked}
      />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h4">Screenshots</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Box
              mb={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {lockPopup && (
                  <>
                    <Button
                      onClick={() => {
                        setDownloadAction("screenAll");
                        setOpen(true);
                      }}
                      sx={{ textTransform: "unset" }}
                    >
                      Download All
                    </Button>

                    <Button
                      onClick={() => {
                        setDownloadAction("gifAll");
                        setOpen(true);
                      }}
                      sx={{ textTransform: "unset" }}
                    >
                      Download GIF
                    </Button>

                    <Button
                      onClick={() => {
                        setDownloadAction("selectedS");
                        setOpen(true);
                      }}
                      disabled={!checked?.length > 0}
                      sx={{ textTransform: "unset" }}
                    >
                      Download
                    </Button>

                    <Button
                      onClick={() => {
                        setDownloadAction("selectedG");
                        setOpen(true);
                      }}
                      disabled={!checked?.length > 0}
                      sx={{ textTransform: "unset" }}
                    >
                      Convert
                    </Button>
                    <Button
                      onClick={() => setChecked([])}
                      disabled={!checked?.length > 0}
                      sx={{ textTransform: "unset" }}
                    >
                      Reset
                    </Button>
                  </>
                )}
                {/* {user?.screenshots && (
                  <Button
                    sx={{ textTransform: "unset" }}
                    onClick={() => {
                      setLockPopup((prv) => !prv);
                      setChecked([]);
                    }}
                  >
                    {lockPopup ? "Cancel" : "Select"}
                  </Button>
                )} */}
              </Box>
            </Box>
            <div className="screenshotsContainer">
              {[...Array(duration)].map((elementInArray, index) => (
                <div
                  key={index}
                  className="imgContainer"
                  onClick={(e) => handleCheck(e, index)}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  {checked.includes(index) && (
                    <Checkbox
                      disableRipple
                      checked
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 99,
                      }}
                    />
                  )}
                  <CustomModalImage
                    checked={checked}
                    lockPopup={lockPopup}
                    index={index}
                    returnImage={returnImage}
                    media={media}
                  />
                </div>
              ))}
            </div>
          </>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
