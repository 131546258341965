import MiniPlayer from "../video/miniPlayer/MiniPlayer";
import "./Suggestions.scss";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";

export default function Suggestions({ nodeId, bank, value, setValue, media }) {
  const user = useSelector((state) => state.user);
  const [bankData, setBankData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [bankError, setBankError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [bankLoading, setBankLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);

  useEffect(() => {
    (async function () {
      if (!bank || !nodeId) return;
      setBankLoading(true);
      try {
        const response = await axios(
          `${process.env.REACT_APP_SERVER_URL}/api/play/bank`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
              bearer: user.token,
              "Content-Type": "application/json",
            },
            data: {
              bank,
              media,
              node: nodeId,
            },
          }
        );
        setBankData(response.data);
      } catch (error) {
        setBankError(error);
      } finally {
        setBankLoading(false);
      }
    })();
  }, [bank]);

  useEffect(() => {
    (async function () {
      if (!nodeId) return;
      setCategoryLoading(true);
      try {
        const response = await axios(
          `${process.env.REACT_APP_SERVER_URL}/api/play/category`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
              bearer: user.token,
              "Content-Type": "application/json",
            },
            data: {
              node: nodeId,
              media,
            },
          }
        );
        setCategoryData(response.data);
      } catch (error) {
        setCategoryError(error);
      } finally {
        setCategoryLoading(false);
      }
    })();
  }, [nodeId]);

  return (
    <Box sx={{ width: "100%" }} pt={0.5} className="suggestions">
      <Tabs
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        variant="fullWidth"
        textColor="none"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          fontSize: "14px",
        }}
      >
        <Tab
          label="Related Features from the Same Bank"
          sx={{ fontSize: "15px" }}
          wrapped
        />
        <Tab
          label="Same Feature from Other Banks"
          sx={{ fontSize: "15px" }}
          wrapped
        />
      </Tabs>

      <Box mt={2}>
        <SwipeableViews index={value}>
          <div className="miniPlayerContainer-suggestions">
            {bankData?.map((video) => (
              <MiniPlayer
                key={video?._id}
                displayNameLength={38}
                url={video?.element[0].Path}
                id={video?.NodeID}
                media={video?.MediaID}
                avatar={video?.ImageURL}
                BankId={video?.BankID}
                BankName={video?.BankName}
                DisplayName={video?.DisplayName}
                imgClassName={
                  video?.MediaID === "16"
                    ? "web-img"
                    : video?.MediaID === "22" || video?.MediaID === "23"
                    ? "ios-img"
                    : video?.MediaID === "27"
                    ? "watch-img"
                    : ""
                }
              />
            ))}
          </div>
          <div className="miniPlayerContainer-suggestions">
            {categoryData?.map((video) => (
              <MiniPlayer
                displayNameLength={38}
                key={video?._id}
                url={video?.element[0].Path}
                id={video?.NodeID}
                avatar={video?.ImageURL}
                media={video?.MediaID}
                BankId={video?.BankID}
                BankName={video?.BankName}
                DisplayName={video?.DisplayName}
                imgClassName={
                  video?.MediaID === "16"
                    ? "web-img"
                    : video?.MediaID === "22" || video?.MediaID === "23"
                    ? "ios-img"
                    : video?.MediaID === "27"
                    ? "watch-img"
                    : ""
                }
              />
            ))}
            {/* <Button sx={{ textTransform: "unset" }}>Show more</Button> */}
          </div>
        </SwipeableViews>
      </Box>
    </Box>
  );
}
