import useAxios from "../../../Hooks/useAxios";
import { fetchPlaylists } from "../../../redux/playlistsSlice";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { Box, IconButton, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function PlaylistsPopover({
  anchorEl,
  setAnchorEl,
  anchorId,
  bank,
  id,
  media,
}) {
  const user = useSelector((state) => state.user);
  const open = Boolean(anchorEl);
  const [addListName, setAddListName] = useState(false);
  const [listName, setListName] = useState(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
    setListName("");
    setAddListName(false);
  };

  const editPlaylist = async (playlistId) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/playlist`,
        {
          method: "PUT",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            playlistId,
            NodeID: id,
            MediaID: media,
            BankID: bank,
          },
        }
      );
      force();
    } catch (error) {
      console.log(error);
    }
  };

  const createPlaylist = async () => {
    if (!listName?.length) return;
    try {
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/playlist`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            name: listName,
            NodeID: id,
            MediaID: media,
            BankID: bank,
          },
        }
      );
      force();
      dispatch(fetchPlaylists());
    } catch (error) {
      console.log(error);
    } finally {
      setListName("");
      setAddListName(false);
    }
  };

  const { data, error, isLoading, force } = useAxios("/api/play/playlist", {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      bearer: user.token,
      "Content-Type": "application/json",
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") createPlaylist();
  };

  return (
    <Popover
      id={anchorId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {!isLoading ? (
        <>
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 1,
              paddingLeft: 3,
            }}
          >
            <Typography textAlign="center">Add to a Playlist</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <List
            sx={{
              pt: 0,
              minWidth: "300px",
              maxHeight: "30em",
              overflow: "auto",
            }}
          >
            {data?.map((i) => (
              <ListItem disableGutters key={i._id}>
                <ListItemButton onClick={() => editPlaylist(i?._id)}>
                  <ListItemAvatar>
                    <Avatar sx={{ width: 35, height: 35 }}>
                      {i?.elements?.some(
                        (e) =>
                          e.BankID == bank &&
                          e.MediaID == media &&
                          e.NodeID == id
                      ) ? (
                        <PlaylistAddCheckIcon color="success" />
                      ) : (
                        <PlaylistAddIcon />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={i?.playlistName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <List>
            <ListItem disableGutters>
              {addListName ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      autoFocus
                      placeholder="Playlist name"
                      variant="standard"
                      fullWidth
                      value={listName}
                      onChange={(e) => setListName(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                  <IconButton size="small" onClick={createPlaylist}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => setAddListName(false)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <ListItemButton autoFocus onClick={() => setAddListName(true)}>
                  <ListItemAvatar>
                    <Avatar sx={{ width: 35, height: 35 }}>
                      <AddIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Create a New Playlist" />
                </ListItemButton>
              )}
            </ListItem>
          </List>
        </>
      ) : (
        <Box
          sx={{
            height: "20em",
            width: "15em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Popover>
  );
}
