import Carousel from "../Layouts/Components/Carousel/Carousel";
import ControlsPanel from "../Layouts/Components/ControlsPanel";
import GroupsFilterList from "../Layouts/Components/FilterList/GroupsFilterList";
import MediaFilterList from "../Layouts/Components/FilterList/MediaFilterList";
import NewCategoriesList from "../Layouts/Components/FilterList/NewCategoriesList";
import Footer from "../Layouts/Components/Footer";
import MiniFooter from "../Layouts/Components/MiniFooter";
import PlaylistFilterList from "../Layouts/Components/Playlists/PlaylistFilterList";
import { fetchCategories } from "../redux/categoriesSlice";
import { saveCategories, saveGroups, saveMedia } from "../redux/filtersSlice";
import { fetchGroups } from "../redux/groupsSlice";
import { fetchPlaylists } from "../redux/playlistsSlice";
import Category from "./Category";
import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Home() {
  const [selectedList, setSelectedList] = useState(null);

  const first = new Date("2023-05-13T00:00:00");
  const second = new Date();
  const dispatch = useDispatch();

  const categoriesFilter = useSelector((state) => state.filters.categories);
  const mediaFilter = useSelector((state) => state.filters.media);
  const groupsFilter = useSelector((state) => state.filters.groups);
  const filterBy = useSelector((state) => state.filters.filterBy);

  const dif =
    Math.round((second - first) / (1000 * 60 * 60 * 24) + 50) + 10 * 50402;

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    lastFetchTime: categoriesLastFetchTime,
  } = useSelector((state) => state.categories);

  const {
    data: groupsData,
    isLoading: groupsLoading,
    lastFetchTime: groupsLastFetchTime,
  } = useSelector((state) => state.groups);

  const { data: playlistsData, isLoading: playlistsLoading } = useSelector(
    (state) => state.playlists
  );

  useEffect(() => {
    const shouldFetchData = playlistsData.length === 0;
    if (shouldFetchData) dispatch(fetchPlaylists());
  }, [playlistsData.length]);

  useEffect(() => {
    const shouldFetchData = categoriesData.length === 0;
    // ||
    // Date.now() - categoriesLastFetchTime > 600000;

    if (shouldFetchData) dispatch(fetchCategories());
  }, [categoriesData.length, categoriesLastFetchTime]);

  useEffect(() => {
    const shouldFetchData = groupsData.length === 0;
    // || Date.now() - groupsLastFetchTime > 600000;

    if (shouldFetchData) dispatch(fetchGroups());
  }, [groupsData.length, groupsLastFetchTime]);

  return (
    <>
      {(!categoriesLoading && !groupsLoading && categoriesFilter?.length) ||
      groupsFilter?.length ||
      mediaFilter?.length ? (
        <ControlsPanel />
      ) : null}

      <Box
        className="videoGrid"
        sx={{ height: "100%" }}
        style={
          (!categoriesLoading && !groupsLoading && categoriesFilter?.length) ||
          groupsFilter?.length ||
          mediaFilter?.length
            ? { marginTop: "5em" }
            : { marginTop: "2em" }
        }
      >
        <div sx={{ height: "100%", overflow: "scroll" }}>
          {!groupsLoading && !categoriesLoading && !playlistsLoading ? (
            <Box
              sx={{
                position: "fixed",
                width: "350px",
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
                gap: 3,
              }}
              style={
                (!categoriesLoading &&
                  !groupsLoading &&
                  categoriesFilter?.length) ||
                groupsFilter?.length ||
                mediaFilter?.length
                  ? { height: "87vh", justifyContent: "space-between" }
                  : { height: "90vh" }
              }
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
                <PlaylistFilterList
                  data={playlistsData}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                  collapsible
                />

                <GroupsFilterList
                  data={groupsData}
                  groupsFilter={groupsFilter}
                  setGroupsFilter={saveGroups}
                />

                <MediaFilterList
                  mediaFilter={mediaFilter}
                  setMediaFilter={saveMedia}
                />

                <NewCategoriesList
                  data={categoriesData}
                  categoriesFilter={categoriesFilter}
                  setCategoriesFilter={saveCategories}
                />
              </Box>
              {categoriesFilter?.length ||
              groupsFilter?.length ||
              mediaFilter?.length ? (
                <MiniFooter />
              ) : null}
            </Box>
          ) : (
            <Box
              sx={{
                position: "fixed",
                width: "350px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>

        <div>
          {categoriesFilter?.length ||
          groupsFilter?.length ||
          mediaFilter?.length ? (
            <Category
              categoriesFilter={categoriesFilter}
              groupsFilter={groupsFilter}
              mediaFilter={mediaFilter}
              filterBy={filterBy}
            />
          ) : (
            <div
              style={{
                width: "100%",
                minHeight: "85vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    p: 2,
                    paddingTop: 0,
                  }}
                >
                  {/* <Button sx={{ textTransform: "unset", fontSize: "1rem" }}>
                    Back to FinTech Insights
                </Button> */}
                  <Typography fontSize="1rem">
                    Total Available Videos: {dif.toLocaleString()}
                  </Typography>
                </Box>
                <Carousel
                  endPoint="/api/play/history/user"
                  title="Last Viewed From You"
                />
                <Carousel
                  endPoint="/api/play/history/org"
                  title="Last Viewed From Your Team"
                />
                <Carousel
                  endPoint="/api/play/history/trend"
                  title="Trending From Your Team"
                />
              </div>
              <Footer />
            </div>
          )}
        </div>
      </Box>
    </>
  );
}
