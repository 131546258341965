import { useState } from "react";
import { Lightbox } from "react-modal-image";
import { useSelector } from "react-redux";

export default function CustomModalImage({
  lockPopup,
  index,
  returnImage,
  media,
  checked,
}) {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (lockPopup) return;
    setOpen(true);
  };

  return (
    <>
      {open && (
        <Lightbox
          small={returnImage(index)}
          large={returnImage(index)}
          alt=""
          onClose={handleClose}
          hideDownload={!user.screenshots}
        />
      )}
      <img
        style={
          checked.includes(index)
            ? { opacity: "50%", border: "1px solid black" }
            : { border: "1px solid black" }
        }
        src={returnImage(index)}
        onClick={handleOpen}
        className={
          media == "16" ? "web-img" : media == "27" ? "watch-img" : "ios-img"
        }
      />
    </>
  );
}
