import { Box, Link, Typography } from "@mui/material";
import React from "react";

export default function MiniFooter() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography textAlign="center">
        {new Date().getFullYear()} Scientia FinTech Insights Ltd. &copy;
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <Link
          sx={{ color: "inherit" }}
          href="https://www.fintechinsights.io/privacy-policy?hsLang=en"
          target="_blank"
          underline="none"
        >
          <Typography>Privacy Policy</Typography>
        </Link>

        <Link
          sx={{ color: "inherit" }}
          href="https://www.fintechinsights.io/terms-and-conditions?hsLang=en"
          target="_blank"
          underline="none"
        >
          <Typography>Terms & Conditions</Typography>
        </Link>
      </Box>
    </Box>
  );
}
