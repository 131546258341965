import { CssSelectField, CssTextField } from "../../../Theme/styled";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function ShareModal({
  bank,
  media,
  id,
  openModalShare,
  setOpenModalShare,
}) {
  const user = useSelector((state) => state.user);
  const names = [];
  const [users, setUsers] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [fullName, setFullName] = useState([]);
  const [mailMessage, setMailMessage] = useState("");

  const copyToClipboard = (text) => navigator.clipboard.writeText(text);

  const copyLink = () => {
    const link = `${window.location.origin}/play/${bank}/${media}/${id}`;
    copyToClipboard(link);
    setOpenAlert(true);
  };

  const handleChangeText = (event) => {
    const {
      target: { value },
    } = event;
    setMailMessage(value);
  };

  const handleCloseModalShare = () => {
    setOpenModalShare(false);
    setOpenAlert(false);
    setFullName([]);
    setMailMessage("");
  };

  const handleChangeMail = (event) => {
    const {
      target: { value },
    } = event;
    setFullName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 350,
      },
    },
  };

  useEffect(() => {
    (async function () {
      try {
        const response = await axios(
          `${process.env.REACT_APP_SERVER_URL}/api/getUsers`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
              bearer: user.token,
              "Content-Type": "application/json",
            },
          }
        );
        const users_sorted = response.data[0].users.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
        });
        setUsers(users_sorted);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const sendShare = async (email, firstname, link, message) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/sendShare`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            email,
            firstname,
            message,
            link,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const send_mails = () => {
    const people_mails = [];
    fullName.map((i) => {
      const fname = i.split(/(\s+)/);
      const index = users.findIndex(
        (object) => object.lastName === fname.at(-1)
      );

      people_mails.push(users[index]);
    });
    const link = `${window.location.origin}/play/${bank}/${media}/${id}`;
    people_mails.forEach((item, i) => {
      sendShare(item.email, item.firstName, link, mailMessage);
    });
    handleCloseModalShare();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openAlert}
        autoHideDuration={4000}
        message="Link copied to clipboard"
        onClose={() => setOpenAlert(false)}
      />

      <Modal
        open={openModalShare}
        onClose={handleCloseModalShare}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              width: 800,
              height: "45%",
              position: "absolute",
              top: "20%",
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#344767",
                width: 50,
                height: 50,
                position: "absolute",
                left: "50%",
                zIndex: 100,
                top: "50%",
                borderRadius: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="h6" sx={{ color: "white" }}>
                OR
              </Typography>
            </Box>
            <Grid container spacing={0} columns={16} sx={{ height: 200 }}>
              <Grid item xs={8}>
                <Card
                  sx={{
                    backgroundColor: "",
                    height: "45vh",
                    width: "50%",
                    position: "absolute",
                    borderRadius: "79px 0px 0px 10px",
                  }}
                >
                  <Box p={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        Copy element link to clipboard.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      pt={8}
                    >
                      <Typography>
                        Link:
                        <Box
                          onClick={() => copyLink()}
                          sx={{
                            cursor: "pointer",
                            fontSize: "1rem",
                            wordBreak: "break-all",
                            display: "flex",
                          }}
                        >
                          {`${window.location.origin}/play/${bank}/${media}/${id}`}

                          <ContentCopyIcon
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              width: "1.5rem",
                            }}
                          />
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Card
                  sx={{
                    height: "45vh",
                    backgroundColor: "#344767",
                    width: "100%",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Box p={5}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon
                        fontSize="medium"
                        sx={{ color: "white" }}
                        onClick={handleCloseModalShare}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "1rem" }}
                        color="white"
                      >
                        Share it with your colleagues who are registered FinTech
                        Insights users
                        {users !== null ? (
                          users.map((i) => {
                            names.push(`${i.firstName} ${i.lastName}`);
                          })
                        ) : (
                          <></>
                        )}
                        <FormControl sx={{ m: 1, mt: 4, width: 300 }}>
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            className="input-label-users"
                            sx={{ color: "white" }}
                          >
                            Users
                          </InputLabel>
                          <CssSelectField
                            sx={{ p: 1, color: "white !important" }}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={fullName}
                            // defaultOpen={true}
                            label="Users"
                            defaultOpen={false}
                            onChange={handleChangeMail}
                            // input={<OutlinedInput sx={{p:1}}label="Users" />}
                            renderValue={(selected) => selected.join(", ")}
                            inputProps={{
                              className: "input-label-users",
                            }}
                            MenuProps={MenuProps}
                          >
                            {names !== null ? (
                              names.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={fullName.indexOf(name) > -1}
                                  />

                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))
                            ) : (
                              <></>
                            )}
                          </CssSelectField>
                          <CssTextField
                            sx={{
                              mt: 3,
                              width: 300,
                            }}
                            rows={3}
                            multiline
                            inputProps={{
                              maxLength: 50,
                              style: { color: "white" },
                            }}
                            onChange={handleChangeText}
                            label="Write them something"
                            variant="outlined"
                            // change outline color
                            InputLabelProps={{
                              style: { color: "white" },
                            }}
                          />
                        </FormControl>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        mt: 1,
                        left: "80%",
                      }}
                    >
                      <Button onClick={() => send_mails()}>
                        <Typography sx={{ color: "white" }}>
                          Send Link
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Modal>
    </>
  );
}
