import logoD from "../assets/02_FI.png";
import logoW from "../assets/logo-main-header.svg";
import { Box, Button, Link, Typography, useTheme } from "@mui/material";

export default function NoAccess() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "50vh",
        display: "flex",
        flexDirection: "column",
        paddingTop: 8,
        width: "70%",
        marginInline: "auto",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <img
          src={theme?.palette?.mode == "dark" ? logoD : logoW}
          width="200px"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",

          gap: 1,
          width: "max-content",
          marginInline: "auto",
        }}
      >
        <Typography variant="h4">
          Your organization does not have access on this feature.
        </Typography>
        <Typography variant="h4">
          Please get in touch with our{" "}
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() =>
              (window.location = "mailto:sales@fintechinsights.io")
            }
          >
            sales team
          </Link>{" "}
          for further assistance.
        </Typography>

        <Box pt={5} sx={{ width: "100%" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "unset", p: 1, fontSize: "18px" }}
            fullWidth
            onClick={() =>
              (window.location.href = "https://app.fintechinsights.io")
            }
          >
            Back to FinTech Insights
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
