import TruncatedTextWithTooltip from "../../TruncatedText/TruncatedText";
import "./MiniPlayer.scss";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WatchIcon from "@mui/icons-material/Watch";
import { Avatar, Box, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MiniPlayer({
  displayNameLength = 25,
  url,
  BankId,
  id,
  imgClassName,
  media,
  disabled,
  BankName,
  DisplayName,
  avatar,
}) {
  const navigate = useNavigate();
  let uri = `https://media-cdn.fintechinsights.io/thumb/${url}`;
  uri = uri.replace(".mp4", "/img-0001.jpg");
  uri = uri.replace("&", "_");
  uri = uri.replace("(", "_");
  uri = uri.replace(")", "_");

  return (
    <Card
      className="miniPlayerCard"
      onClick={
        !disabled ? () => navigate(`/play/${BankId}/${media}/${id}`) : null
      }
    >
      <Box className="miniPlayer">
        <img className={imgClassName} src={uri} loading="lazy" />
      </Box>

      {/* card footer */}
      <Box sx={{ cursor: "pointer", padding: 1 }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Avatar alt="Bank logo" src={avatar} sx={{ height: 40, width: 40 }} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TruncatedTextWithTooltip
              text={BankName?.replace("&lt;", "<")}
              fontSize="15px"
              maxLength={displayNameLength}
              fontWeight="bold"
              color="primary"
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {media === "16" && <LaptopChromebookIcon fontSize="small" />}
              {media === "27" && <WatchIcon fontSize="small" />}
              {media === "22" || media === "23" ? (
                <PhoneIphoneIcon fontSize="small" />
              ) : null}
              &#183;
              <Typography sx={{ fontSize: "12px" }}>
                {media === "16" ? "Web" : media === 27 ? "Watch" : "iOS"}
              </Typography>
            </Box>

            <TruncatedTextWithTooltip
              fontSize="12px"
              text={DisplayName}
              maxLength={displayNameLength}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
