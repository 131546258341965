export function set(name, value, daysToLive) {
  let date = new Date(Date.now() + daysToLive * 86400e3);
  date = date.toUTCString();
  const expires = `expires=${date}`;
  document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=Lax`;
}
export function setWithDomain(name, value, daysToLive, domain) {
  let date = new Date(Date.now() + daysToLive * 86400e3);
  date = date.toUTCString();
  const expires = `expires=${date}`;
  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${domain}; SameSite=Lax`;
}
export function get(name) {
  const cDecoded = decodeURIComponent(document.cookie);
  const cArray = cDecoded.split("; ");
  let result;
  cArray.forEach((element) => {
    if (element.indexOf(name) == 0) {
      result = element.substring(name.length + 1);
    }
  });
  return result;
}

export function erase(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Lax`;
}
export function eraseFromDomain(name, domain) {
  document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Lax`;
}
