import React from "react";
import ReactDOM from "react-dom/client";
// Rtk imports
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
// Mui theme imports
import CssBaseline from "@mui/material/CssBaseline";

import { store } from "./redux/store";
import { darkTheme, lightTheme } from "./Theme/theme";
import * as Cookies from "./Utilities/cookies";
import App from "./App";

// Mui theme declaration
let theme;
const themeCookie = Cookies.get("theme");
const preferDark = window.matchMedia("(prefers-color-scheme: dark)");

// Determine theme based on cookie or system preference
if (themeCookie && themeCookie === "dark") theme = createTheme(darkTheme);
else if (themeCookie && themeCookie === "light")
  theme = createTheme(lightTheme);
else if (preferDark.matches) {
  Cookies.set("theme", "dark", 365);
  theme = createTheme(darkTheme);
} else {
  Cookies.set("theme", "light", 365);
  theme = createTheme(lightTheme);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
);
