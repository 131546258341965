import "./style.css";

const PrimaryButton = ({
  children,
  onClick,
  color,
  fontWeight,
  fontSize,
  disabled,
  fullWidth,
  size,
  width,
  height,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={
      color == "white"
        ? "PrimaryButton btn-prv-white"
        : "PrimaryButton btn-prim-blue"
    }
    onClick={onClick}
    style={{
      height: height ?? null,
      width: fullWidth ? "100%" : width ?? null,
      fontWeight: fontWeight ?? null,
      fontSize: fontSize ?? null,
      cursor: disabled ? "default" : "pointer",
      opacity: disabled ? "0.5" : null,
      padding:
        width || height ? null : size === "small" ? "6px 18px" : "9px 24px",
    }}
  >
    {children}
  </button>
);

export default PrimaryButton;
