import { useEffect, useState } from "react";
import axios from "axios";

const useAxios = (url, options = {}, cancel) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortController = new AbortController();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios(process.env.REACT_APP_SERVER_URL + url, {
        ...options,
        signal: abortController.signal,
      });
      setData(response.data);
    } catch (error) {
      if (!abortController.signal.aborted) setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cancel) return;

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [url, cancel]);

  const force = () => {
    setData(null);
    setIsLoading(true);
    fetchData();
  };

  return { data, isLoading, error, force };
};

export default useAxios;
