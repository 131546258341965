import GroupsListItem from "./GroupsListItem";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function GroupsFilterList({
  groupsFilter,
  setGroupsFilter,
  data,
  filters,
}) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const handleCheckChildren = (e, item) => {
    e.stopPropagation();
    const newChecked = [...groupsFilter];
    let flag = true;
    if (item?.banks.every((i) => newChecked.includes(i))) flag = false;
    if (filters && item?.banks.some((i) => newChecked.includes(i)))
      flag = false;

    item?.banks?.forEach((i) => {
      const currentIndex = newChecked.indexOf(i);
      if (currentIndex !== -1) newChecked.splice(currentIndex, 1);
    });

    if (filters && flag) {
      item?.banks?.forEach((i) => {
        if (filters?.some((e) => e.BankID == i.BankID)) newChecked.push(i);
      });
      return dispatch(setGroupsFilter(newChecked));
    }

    if (flag) item?.banks?.forEach((i) => newChecked.push(i));
    dispatch(setGroupsFilter(newChecked));
  };

  const handleCheck = (value) => {
    const newChecked = [...groupsFilter];
    const currentIndex = newChecked.indexOf(value);
    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    dispatch(setGroupsFilter(newChecked));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {data && (
        <List dense disablePadding>
          <ListItem
            onClick={() => setOpen((prv) => !prv)}
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton>
              <AccountBalanceIcon />
              <ListItemText
                primary="Banks"
                primaryTypographyProps={{
                  fontSize: "19px",
                  marginLeft: 1.5,
                  fontWeight: "bold",
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        {data
          ?.filter((i) => i._id.GroupTitle != "Admin ALL")
          ?.map((item) => (
            <GroupsListItem
              key={item._id.GroupTitle}
              item={item}
              groupsFilter={groupsFilter}
              handleCheckChildren={handleCheckChildren}
              handleCheck={handleCheck}
              filters={filters}
            />
          ))}
      </Collapse>
    </Box>
  );
}
