import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useEffect } from "react";

export default function WarningModal({
  callback,
  title = "This action can not be undone",
  message = "Are you sure you want to proceed with that action?",
  open,
  setOpen,
}) {
  useEffect(() => {
    if (!open) return;
    const handleKeyPress = (event) => {
      if (event.key === "Enter") callback();
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{ style: { backgroundColor: "#000000A3" } }}
      style={{ outline: "none" }}
      disableEnforceFocus
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 560,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          outline: "none",
          borderRadius: "10px",
        }}
      >
        {/* title */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", lineHeight: "27px", fontWeight: 700 }}
          >
            {title}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* content */}
        <Box>
          <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
            {message}
          </Typography>
          <Box
            pt={8}
            pb={1}
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
          >
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{
                textTransform: "unset",
                borderRadius: "8px",
              }}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={callback}
              sx={{ textTransform: "unset", borderRadius: "8px" }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
