import { MainContainer, StyledControlsPanel } from "../../Theme/styled";
import { flashFilters, saveFilterBy } from "../../redux/filtersSlice";
import SortIcon from "@mui/icons-material/Sort";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function ControlsPanel({ query }) {
  const dispatch = useDispatch();
  const categoriesFilter = useSelector((state) => state.filters.categories);
  const mediaFilter = useSelector((state) => state.filters.media);
  const groupsFilter = useSelector((state) => state.filters.groups);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterBy = useSelector((state) => state.filters.filterBy);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          fontWeight="bold"
          pt={1}
          textAlign="center"
          sx={{ minWidth: "150px" }}
        >
          Sort By
        </Typography>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(saveFilterBy("Bank"));
                setAnchorEl(null);
              }}
            >
              <ListItemText
                sx={filterBy === "Bank" ? { color: "#4a59ff" } : null}
                primary="Bank"
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(saveFilterBy("Node"));
                setAnchorEl(null);
              }}
            >
              <ListItemText
                primary="Functionality"
                sx={filterBy === "Node" ? { color: "#4a59ff" } : null}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <StyledControlsPanel>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "1920px",
            marginInline: "auto",
          }}
          pl={15}
          pr={5.4}
          pt={0.5}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 20 }}>
            <Button
              disabled={
                !categoriesFilter?.length &&
                !mediaFilter?.length &&
                !groupsFilter?.length
              }
              onClick={() => dispatch(flashFilters())}
              sx={{ textTransform: "unset" }}
            >
              Reset Filters
            </Button>
            {query && (
              <Typography variant="h4">Search results for: {query}</Typography>
            )}
          </Box>
          <IconButton
            aria-describedby={id}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <SortIcon />
          </IconButton>
        </Box>
      </StyledControlsPanel>
    </>
  );
}
