import ControlsPanel from "../Layouts/Components/ControlsPanel";
import GroupsFilterList from "../Layouts/Components/FilterList/GroupsFilterList";
import MediaFilterList from "../Layouts/Components/FilterList/MediaFilterList";
import NewCategoriesList from "../Layouts/Components/FilterList/NewCategoriesList";
import MiniFooter from "../Layouts/Components/MiniFooter";
import PlaylistFilterList from "../Layouts/Components/Playlists/PlaylistFilterList";
import MiniPlayer from "../Layouts/Components/video/miniPlayer/MiniPlayer";
import { fetchCategories } from "../redux/categoriesSlice";
import {
  flashFilters,
  saveCategories,
  saveGroups,
  saveMedia,
} from "../redux/filtersSlice";
import { fetchGroups } from "../redux/groupsSlice";
import { fetchPlaylists } from "../redux/playlistsSlice";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Search() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { query } = useParams();
  const [step, setStep] = useState(0);
  const [lockLoadMoreButton, setLockLoadMoreButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [noMoreDataToReceive, setNoMoreDataToReceive] = useState(false);
  const { ref, inView } = useInView({ rootMargin: "150px" });
  const [filters, setFilters] = useState(null);
  const categoriesFilter = useSelector((state) => state.filters.categories);
  const mediaFilter = useSelector((state) => state.filters.media);
  const groupsFilter = useSelector((state) => state.filters.groups);
  const filterBy = useSelector((state) => state.filters.filterBy);

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    lastFetchTime: categoriesLastFetchTime,
  } = useSelector((state) => state.categories);

  const {
    data: groupsData,
    isLoading: groupsLoading,
    lastFetchTime: groupsLastFetchTime,
  } = useSelector((state) => state.groups);

  useEffect(() => {
    const shouldFetchData = categoriesData.length === 0;
    // ||
    // Date.now() - categoriesLastFetchTime > 600000;

    if (shouldFetchData) dispatch(fetchCategories());
  }, [categoriesData.length, categoriesLastFetchTime]);

  useEffect(() => {
    const shouldFetchData = groupsData.length === 0;
    // || Date.now() - groupsLastFetchTime > 600000;

    if (shouldFetchData) dispatch(fetchGroups());
  }, [groupsData.length, groupsLastFetchTime]);

  const { data: playlistsData, isLoading: playlistsLoading } = useSelector(
    (state) => state.playlists
  );

  useEffect(() => {
    const shouldFetchData = playlistsData.length === 0;
    if (shouldFetchData) dispatch(fetchPlaylists());
  }, [playlistsData.length]);

  const abortControllerRef = useRef();

  const fetchData = async (e, step) => {
    const categoriesF = [];
    categoriesFilter?.forEach((i) => {
      categoriesF.push(i.ID);
      // categoriesF.push(i.ParentID);
    });

    if (abortControllerRef.current) abortControllerRef.current.abort();

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const { signal } = abortController;

    try {
      if (!e) setIsLoading(true);
      else setLockLoadMoreButton(true);
      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/search`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            groupsF: groupsFilter?.map((i) => i.BankID),
            categoriesF,
            mediaF: mediaFilter.map((i) =>
              i === "Web" ? "16" : i === "iOS" ? "22" : "27"
            ),
            step,
            search: query,
            filterBy,
          },
          signal,
        }
      );
      if (e) {
        const temp = [...data, ...response?.data?.docs];
        setData(temp);
      } else {
        setData(response.data.docs);
        setFilters(response?.data?.filters);
      }
      if (response.data.docs.length < 30) setNoMoreDataToReceive(true);
      setIsLoading(false);
    } catch (error) {
      if (!axios.isCancel(err)) {
        setError(error);
        setIsLoading(false);
      }
    } finally {
      setLockLoadMoreButton(false);
    }
  };

  const fetchMore = () => {
    fetchData(true, step);
    setStep((prv) => prv + 1);
  };

  useEffect(() => {
    setFilters(null);
  }, [query]);

  useEffect(() => {
    if (!query) return;
    fetchData(false, 0);
    setStep(1);
    setNoMoreDataToReceive(false);
    window.scrollTo(0, 0);
  }, [query, categoriesFilter, groupsFilter, mediaFilter, filterBy]);

  useEffect(() => {
    if (inView) fetchMore();
  }, [inView]);

  if (error)
    return (
      <Typography>Something went wrong while loading the video.</Typography>
    );

  return (
    <>
      {!groupsLoading && !categoriesLoading && filters && (
        <ControlsPanel query={query} />
      )}
      <Box className="videoGrid" sx={{ height: "100%", marginTop: "5em" }}>
        <div sx={{ height: "100%", overflow: "scroll" }}>
          {!groupsLoading && !categoriesLoading && filters ? (
            <Box
              sx={{
                position: "fixed",
                width: "350px",
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
                height: "87vh",
                justifyContent: "space-between",
                gap: 3,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
                <GroupsFilterList
                  data={groupsData}
                  groupsFilter={groupsFilter}
                  setGroupsFilter={saveGroups}
                  filters={filters}
                />

                <MediaFilterList
                  mediaFilter={mediaFilter}
                  setMediaFilter={saveMedia}
                  filters={filters}
                />

                <NewCategoriesList
                  data={categoriesData}
                  categoriesFilter={categoriesFilter}
                  setCategoriesFilter={saveCategories}
                  filters={filters}
                />
              </Box>
              <MiniFooter />
            </Box>
          ) : (
            <Box
              sx={{
                position: "fixed",
                width: "350px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>

        <div>
          {isLoading ? (
            <div className="miniPlayerContainer">
              {[...Array(30)].map((i, index) => (
                <Box key={index} sx={{ aspectRatio: "3/2" }}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height="100%"
                    width="100%"
                    sx={{ borderRadius: "5px", marginBottom: 2 }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "20px" }}
                    width="70%"
                  />
                  <Skeleton variant="text" sx={{ fontSize: "20px" }} />
                </Box>
              ))}
            </div>
          ) : (
            <>
              <div
                className="miniPlayerContainer"
                style={{ paddingBottom: "2em" }}
              >
                {data?.length ? (
                  data?.map((video) => (
                    <MiniPlayer
                      key={video?._id}
                      url={video?.element[0]?.Path}
                      BankId={video?.BankID}
                      avatar={video?.ImageURL}
                      id={video?.NodeID}
                      BankName={video?.BankName}
                      DisplayName={video?.DisplayName}
                      media={video?.MediaID}
                      imgClassName={
                        video?.MediaID === "16"
                          ? "web-img"
                          : video?.MediaID === "22" || video?.MediaID === "23"
                          ? "ios-img"
                          : video?.MediaID === "27"
                          ? "watch-img"
                          : ""
                      }
                    />
                  ))
                ) : (
                  <Typography>No video found with these filters.</Typography>
                )}
              </div>
              {!lockLoadMoreButton ? (
                <>
                  {data?.length && !noMoreDataToReceive ? (
                    <div ref={ref} />
                  ) : null}
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          )}
        </div>
      </Box>
    </>
  );
}
