import useAxios from "../Hooks/useAxios";
import MiniFooter from "../Layouts/Components/MiniFooter";
import PlaylistFilterList from "../Layouts/Components/Playlists/PlaylistFilterList";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Playlists() {
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const [selectedList, setSelectedList] = useState(id);

  const { data, error, isLoading, force } = useAxios(
    "/api/play/playlists/org",
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: user.token,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <>
      {!isLoading ? (
        <Box className="videoGrid" sx={{ height: "100%" }}>
          <div sx={{ height: "100%", overflow: "scroll" }}>
            <Box
              sx={{
                position: "fixed",
                width: "350px",
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
                justifyContent: "space-between",
                height: "94vh",
              }}
            >
              <PlaylistFilterList
                data={data}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />

              <MiniFooter />
            </Box>
          </div>
          <Typography p={2}>Select a playlist</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
