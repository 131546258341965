import { AppBar, Container, Select, TextField } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";

// Containers
export const MainContainer = styled(Container)({
  width: "100%",
  maxWidth: "1920px",
});

// Navbar
export const AppAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary,
  position: "fixed",
  width: "100%",
  left: 0,
  top: 0,
  zIndex: 999,
}));

export const AppBarContent = styled("div")({
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
  padding: "5px", // padding off the navbar
});

// Footer
export const AppFooter = styled("footer")(({ theme }) => ({
  // borderTop: "1px solid grey",
  paddingTop: 30,
  paddingBottom: 0,
  backgroundColor: theme.palette.background.default,
  marginTop: "auto",
  flexShrink: 0,
}));

export const StyledBreadCrumbs = styled(Breadcrumbs)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: 99,
}));

export const StyledControlsPanel = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: 99,
  position: "fixed",
  width: "100vw",
  padding: 10,
  top: 60,
  left: 0,
}));

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export const CssSelectField = styled(Select)(({ theme }) => ({
  "&:before": {
    borderColor: "white !important",
  },
  "&:after": {
    borderColor: "white !important",
  },
  "&:not(.Mui-disabled):hover::before": {
    borderColor: "white !important",
  },
  "& .MuiSelect-icon": {
    fill: "white !important",
  },
  "& .MuiSelect-root": {
    color: "white !important",
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white !important",
    },
    "&:hover fieldset": {
      borderColor: "white !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white !important",
    },
    "&.MuiSelect-iconOutlined": {
      color: "white !important",
    },
  },
}));
