import categoriesReducer from "./categoriesSlice";
import filtersReducer from "./filtersSlice";
import groupsReducer from "./groupsSlice";
import playlistsReducer from "./playlistsSlice";
import userReducer from "./userSlice";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    user: userReducer,
    groups: groupsReducer,
    categories: categoriesReducer,
    filters: filtersReducer,
    playlists: playlistsReducer,
  },
});
