import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WatchIcon from "@mui/icons-material/Watch";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

export default function PlayerHeader({
  otherBanksAll,
  otherBanksAllError,
  otherBanksAllLoading,
  otherMedia,
  bank,
  id,
  data,
  bankName,
  media,
}) {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(otherBanksAll?.map((i) => i?.BankName));
  }, [otherBanksAll]);

  const handleChange = (value) => {
    if (!value) return;
    const selected = otherBanksAll?.find((i) => i?.BankName === value);
    navigate(`/play/${selected?.BankID}/${media}/${id}`);
  };

  return (
    <Box mt={1} mb={1}>
      <Card>
        <Box
          p={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img
              alt="Bank logo"
              style={{
                height: 50,
                width: "auto",
                borderRadius: "3px",
              }}
              src={data?.ImageURL}
            />
            <Autocomplete
              disablePortal
              onChange={(event, value) => handleChange(value)}
              id="combo-box-demo"
              options={options?.sort()}
              sx={{ width: 280 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={bankName}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "bold" },
                  }}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <IconButton
              onClick={() => navigate(`/play/${bank}/22/${id}`)}
              disabled={
                !otherMedia?.some((i) => i.MediaID == "22" || i.MediaID == "23")
              }
            >
              <PhoneIphoneIcon
                fontSize="small"
                color={media == "22" || media == "23" ? "primary" : ""}
              />
            </IconButton>

            <IconButton
              onClick={() => navigate(`/play/${bank}/16/${id}`)}
              disabled={!otherMedia?.some((i) => i.MediaID == "16")}
            >
              <LaptopChromebookIcon
                fontSize="small"
                color={media == "16" ? "primary" : ""}
              />
            </IconButton>

            <IconButton
              onClick={() => navigate(`/play/${bank}/27/${id}`)}
              disabled={!otherMedia?.some((i) => i.MediaID == "27")}
            >
              <WatchIcon
                fontSize="small"
                color={media == "27" ? "primary" : ""}
              />
            </IconButton>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
