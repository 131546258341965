import { Navigate } from "react-router-dom";

import Login from "../Pages/Login";

export const authRoutes = [
  {
    index: true,
    element: <Login />,
  },

  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];
