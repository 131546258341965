import useAxios from "../../../../Hooks/useAxios";
import { fetchCategories } from "../../../../redux/categoriesSlice";
import { fetchGroups } from "../../../../redux/groupsSlice";
import CustomSeparatorBreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import Footer from "../../Footer";
import PlayerHeader from "../../PlayerHeader/PlayerHeader";
import PlaylistsPopover from "../../Playlists/PlaylistsPopover";
import ScreenShots from "../../ScreenShots";
import ShareModal from "../../ShareModal/ShareModal";
import Suggestions from "../../Suggestions/Suggestions";
import "./Player.scss";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShareIcon from "@mui/icons-material/Share";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Player() {
  const dispatch = useDispatch();

  const [value, setValue] = useState(1);

  const { bank, id, media } = useParams();
  const user = useSelector((state) => state.user);
  const [videoId, setVideoId] = useState(null);
  const [mediaId, setMediaId] = useState(null);
  const [bankId, setBankId] = useState(null);

  const [url, setUrl] = useState(null);
  const [description, setDescription] = useState(null);
  const [bankName, setBankName] = useState(null);

  const [displayName, setDisplayName] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);
  const [duration, setDuration] = useState(null);
  const [otherMedia, setOtherMedia] = useState(null);

  const [openModalShare, setOpenModalShare] = useState(false);

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (!video) return;
    setDuration(Math.floor(video.duration));
  };

  const { data, error, isLoading, force } = useAxios(
    "/api/play",
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: user.token,
        "Content-Type": "application/json",
      },
      data: {
        bank,
        id,
        media,
      },
    },
    !bank || !id || !media
  );

  const {
    data: otherBanksAll,
    error: otherBanksAllError,
    isLoading: otherBanksAllLoading,
    force: otherBanksAllForce,
  } = useAxios(
    "/api/play/category/all",
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        bearer: user.token,
        "Content-Type": "application/json",
      },
      data: {
        node: id,
        media,
      },
    },
    !id || !media
  );

  useEffect(() => {
    if (!data) return;

    const currentMedia = data?.element?.find(
      (i) => i.MediaID === data?.MediaID
    );
    const otherMediaTemp = data?.element?.filter(
      (i) => i.MediaID !== data?.MediaID && i.MediaID !== "23"
    );

    // Set all state updates except for 'url'
    setOtherMedia(otherMediaTemp);
    setBankName(data.BankName);
    setVideoId(data?.NodeID);
    setMediaId(data?.MediaID);
    setBankId(data?.BankID);
    setDescription(data.Description);
    setDisplayName(data.DisplayName);
    setIsPlaying(false);

    // Schedule the url update in the next rendering cycle
    setTimeout(() => {
      setUrl(currentMedia?.Path);
    }, 0);
  }, [data]);

  useEffect(() => {
    if (videoId && videoId != id) {
      force();
      otherBanksAllForce();
    } else if (bankId && bankId != bank) {
      force();
      otherBanksAllForce();
    } else if (mediaId && mediaId != media) {
      force();
      otherBanksAllForce();
    }
  }, [id, media, bank]);

  const returnImage = (id) => {
    let uri = `https://media-cdn.fintechinsights.io/thumb/${url}`;
    if (id < 9) uri = uri.replace(".mp4", `/img-000${id + 1}.jpg`);
    else if (id < 99) uri = uri.replace(".mp4", `/img-00${id + 1}.jpg`);
    else uri = uri.replace(".mp4", `/img-0${id + 1}.jpg`);
    uri = uri.replace("&", "_");
    uri = uri.replace("(", "_");
    uri = uri.replace(")", "_");
    return uri;
  };

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    lastFetchTime: categoriesLastFetchTime,
  } = useSelector((state) => state.categories);

  const {
    data: groupsData,
    isLoading: groupsLoading,
    lastFetchTime: groupsLastFetchTime,
  } = useSelector((state) => state.groups);

  // when i dispatch the loginSuccess, also fetch the data
  useEffect(() => {
    const shouldFetchData = categoriesData.length === 0;
    // ||
    // Date.now() - categoriesLastFetchTime > 600000;

    if (shouldFetchData) dispatch(fetchCategories());
  }, [categoriesData.length, categoriesLastFetchTime]);

  useEffect(() => {
    const shouldFetchData = groupsData.length === 0;
    // || Date.now() - groupsLastFetchTime > 600000;

    if (shouldFetchData) dispatch(fetchGroups());
  }, [groupsData.length, groupsLastFetchTime]);

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorId = open ? "simple-popover" : undefined;

  if (error?.response?.status === 404)
    return <Typography>Video not found.</Typography>;

  if (error)
    return (
      <Typography>Something went wrong while loading the video.</Typography>
    );

  return (
    <>
      <ShareModal
        openModalShare={openModalShare}
        setOpenModalShare={setOpenModalShare}
        bank={bank}
        media={media}
        id={id}
      />

      <PlaylistsPopover
        bank={bank}
        media={media}
        id={id}
        anchorId={anchorId}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClick={(event) => setAnchorEl(event.currentTarget)}
      />

      <Box
        sx={{
          display: "flex",
          gap: 3,
        }}
      >
        {isLoading ||
        groupsLoading ||
        categoriesLoading ||
        otherBanksAllLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 130px)",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ flex: 1 }} mt={2}>
              <PlayerHeader
                otherBanksAll={otherBanksAll}
                otherBanksAllError={otherBanksAllError}
                otherBanksAllLoading={otherBanksAllLoading}
                otherMedia={otherMedia}
                bank={bank}
                id={id}
                data={data}
                bankName={bankName}
                media={media}
              />
              <Box pt={1} className="player">
                {url && (
                  <>
                    <video
                      onContextMenu={(e) => e.preventDefault()}
                      onLoadedMetadata={handleLoadedMetadata}
                      ref={videoRef}
                      onEnded={() => setIsPlaying(false)}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      width="100%"
                      height="100%"
                      controls
                      muted
                      preload="auto"
                      controlsList="nodownload"
                      autoPlay
                      playsInline
                      onCanPlayThrough={() => {
                        videoRef.current.pause();
                      }}
                    >
                      <source
                        src={`https://data.fintechinsights.io/${url}`}
                        type="video/mp4"
                      />
                    </video>
                  </>
                )}

                {url && (
                  <div
                    onClick={() => {
                      videoRef.current.play();
                    }}
                    className={
                      isPlaying ? "replayButton hide" : "replayButton show"
                    }
                  >
                    <PlayArrowIcon sx={{ color: "white" }} fontSize="large" />
                  </div>
                )}
              </Box>

              {!isLoading && !groupsLoading && !categoriesLoading && (
                <Box
                  pt={2}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CustomSeparatorBreadCrumbs
                    bank={bankName}
                    category={displayName}
                    media={mediaId}
                    groupsData={groupsData}
                    categoriesData={categoriesData}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Add to Playlist">
                      <IconButton
                        aria-describedby={anchorId}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                      >
                        <PlaylistAddIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="See this Functionality on FinTech Insights">
                      <IconButton
                        onClick={() =>
                          (location.href = `https://app.fintechinsights.io/analyzed/bank/${data?.BankID}/${data?.MediaID}/${data?.ParentID}/${data?.NodeID}`)
                        }
                      >
                        <AssessmentIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Share this with your Organisation">
                      <IconButton onClick={() => setOpenModalShare(true)}>
                        <ShareIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  width: "100%",
                  paddingBottom: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              />
              <Typography fontSize="20px" sx={{ fontWeight: "bold" }}>
                {displayName}
              </Typography>
              <Typography fontSize="17px">{description}</Typography>

              {/* screenshots */}
              {url && duration && (
                <ScreenShots
                  media={media}
                  duration={duration}
                  returnImage={returnImage}
                  url={url}
                />
              )}
            </Box>
            <Suggestions
              value={value}
              setValue={setValue}
              nodeId={data?.NodeID}
              bank={data?.BankID}
              media={data?.MediaID}
            />
          </>
        )}
      </Box>
      <Footer />
    </>
  );
}
