import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemIcon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";

export default function GroupsListItem({
  item,
  groupsFilter,
  handleCheck,
  handleCheckChildren,
  filters,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (filters) {
      if (
        item?.banks?.some((i) =>
          filters?.some((e) => e?.BankID == i?.BankID)
        ) &&
        item?.banks?.some((i) => groupsFilter.includes(i))
      )
        setOpen(true);
    } else if (item?.banks?.some((i) => groupsFilter.includes(i)))
      setOpen(true);
  }, []);

  return (
    <List dense disablePadding>
      <ListItem
        onClick={() => setOpen((prv) => !prv)}
        key={item?._id?.GroupTitle}
        secondaryAction={
          <IconButton edge="end" aria-label="comments">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          disabled={
            filters
              ? item?.banks?.every(
                  (i) => !filters.some((e) => e.BankID == i.BankID)
                )
              : false
          }
        >
          <ListItemIcon>
            <Checkbox
              indeterminate={
                item?.banks?.some((i) => groupsFilter.includes(i)) &&
                !item?.banks?.every((i) => groupsFilter.includes(i))
              }
              edge="start"
              tabIndex={-1}
              checked={item?.banks?.every((i) => groupsFilter.includes(i))}
              onClick={(e) => handleCheckChildren(e, item)}
              disableRipple
              inputProps={{ "aria-labelledby": item?._id?.GroupTitle }}
            />
          </ListItemIcon>
          <ListItemText
            id={item?._id?.GroupTitle}
            primary={item?._id?.GroupTitle}
            primaryTypographyProps={{ fontSize: "14px" }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {item?.banks?.map((value) => (
          <ListItem key={value?.BankID}>
            <ListItemButton
              disabled={
                filters
                  ? !filters?.some((i) => i.BankID == value.BankID)
                  : false
              }
              dense
              onClick={() => handleCheck(value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={groupsFilter.includes(value)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                id={value?.BankID}
                primary={value?.BankName.replace("&lt;", " <")}
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
}
