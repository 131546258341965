import MiniPlayer from "../Layouts/Components/video/miniPlayer/MiniPlayer";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

export default function Category({
  groupsFilter,
  categoriesFilter,
  mediaFilter,
  filterBy,
}) {
  const [step, setStep] = useState(0);

  const user = useSelector((state) => state.user);

  const [lockLoadMoreButton, setLockLoadMoreButton] = useState(false);

  const [noMoreDataToReceive, setNoMoreDataToReceive] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const { ref, inView } = useInView({ rootMargin: "150px" });
  const abortControllerRef = useRef();

  const fetchData = async (e, step) => {
    const categoriesF = [];
    categoriesFilter?.forEach((i) => {
      categoriesF.push(i.ID);
      // categoriesF.push(i.ParentID);
    });

    if (abortControllerRef.current) abortControllerRef.current.abort();

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const { signal } = abortController;

    try {
      if (!e) setIsLoading(true);
      else setLockLoadMoreButton(true);

      const response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/play/mainCategory`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            bearer: user.token,
            "Content-Type": "application/json",
          },
          data: {
            filterBy,
            groupsF: groupsFilter?.map((i) => i.BankID),
            categoriesF,
            mediaF: mediaFilter.map((i) =>
              i === "Web" ? "16" : i === "iOS" ? "22" : "27"
            ),
            step,
          },
          signal,
        }
      );
      if (e) {
        const temp = [...data, ...response?.data?.docs];
        setData(temp);
      } else {
        setData(response.data.docs);
      }
      if (response.data.docs.length < 30) setNoMoreDataToReceive(true);
      setIsLoading(false);
    } catch (error) {
      if (!abortController.signal.aborted) {
        setError(error);
        setIsLoading(false);
      }
    } finally {
      setLockLoadMoreButton(false);
    }
  };

  const fetchMore = () => {
    fetchData(true, step);
    setStep((prv) => prv + 1);
  };

  useEffect(() => {
    fetchData(false, 0);
    setStep(1);
    setNoMoreDataToReceive(false);
    window.scrollTo(0, 0);
  }, [categoriesFilter, groupsFilter, mediaFilter, filterBy]);

  useEffect(() => {
    if (inView) fetchMore();
  }, [inView]);

  if (error)
    return (
      <Typography>Something went wrong while loading the video.</Typography>
    );

  return (
    <>
      {isLoading ? (
        <div className="miniPlayerContainer">
          {[...Array(30)].map((i, index) => (
            <Box key={index} sx={{ aspectRatio: "3/2" }}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height="100%"
                width="100%"
                sx={{ borderRadius: "5px", marginBottom: 2 }}
              />
              <Skeleton variant="text" sx={{ fontSize: "20px" }} width="70%" />
              <Skeleton variant="text" sx={{ fontSize: "20px" }} />
            </Box>
          ))}
        </div>
      ) : (
        <>
          <div className="miniPlayerContainer" style={{ paddingBottom: "2em" }}>
            {data?.length ? (
              data?.map((video) => (
                <MiniPlayer
                  key={video?._id}
                  url={video?.element[0]?.Path}
                  avatar={video?.ImageURL}
                  BankId={video?.BankID}
                  BankName={video?.BankName}
                  DisplayName={video?.DisplayName}
                  id={video?.NodeID}
                  media={video?.MediaID}
                  imgClassName={
                    video?.MediaID === "16"
                      ? "web-img"
                      : video?.MediaID === "22" || video?.MediaID === "23"
                      ? "ios-img"
                      : video?.MediaID === "27"
                      ? "watch-img"
                      : ""
                  }
                />
              ))
            ) : (
              <Typography>No video found with these filters.</Typography>
            )}
          </div>
          {!lockLoadMoreButton ? (
            <>
              {data?.length && !noMoreDataToReceive ? <div ref={ref} /> : null}
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </>
  );
}
