import useAxios from "../../../Hooks/useAxios";
import MiniPlayer from "../video/miniPlayer/MiniPlayer";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Skeleton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export default function Carousel({
  endPoint = "/api/play",
  title,
  slides = 4,
}) {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const settings = {
    prevArrow: (
      <ArrowBackIosNewIcon
        sx={
          theme?.palette?.mode == "dark"
            ? { color: "white !important" }
            : { color: "#000000DE !important" }
        }
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={
          theme?.palette?.mode == "dark"
            ? { color: "white !important" }
            : { color: "#000000DE !important" }
        }
      />
    ),
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: slides,
    slidesToScroll: slides,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data, error, isLoading } = useAxios(endPoint, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      bearer: user.token,
      "Content-Type": "application/json",
    },
  });

  if (data && !data?.length) return null;

  return (
    <Box sx={{ width: "97%", marginInline: "auto" }}>
      {!isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 10,
          }}
        >
          <Typography pl={1} variant="h4">
            {title}
          </Typography>
          {data?.length > 4 ? (
            <Slider {...settings}>
              {data?.map((video) => (
                <MiniPlayer
                  avatar={video?.ImageURL}
                  key={video?.node?._id}
                  url={video?.nodeElement?.Path}
                  BankId={video?.node?.BankID}
                  id={video?.node?.NodeID}
                  media={video?.node?.MediaID}
                  BankName={video?.node?.BankName}
                  DisplayName={video?.node?.DisplayName}
                  imgClassName={
                    video?.node?.MediaID === "16"
                      ? "web-img"
                      : video?.node?.MediaID === "22" ||
                        video?.node?.MediaID === "23"
                      ? "ios-img"
                      : video?.node?.MediaID === "27"
                      ? "watch-img"
                      : ""
                  }
                />
              ))}
            </Slider>
          ) : (
            <div className="miniPlayerContainer">
              {data?.map((video) => (
                <MiniPlayer
                  avatar={video?.ImageURL}
                  key={video?.node?._id}
                  url={video?.nodeElement?.Path}
                  BankId={video?.node?.BankID}
                  id={video?.node?.NodeID}
                  media={video?.node?.MediaID}
                  BankName={video?.node?.BankName}
                  DisplayName={video?.node?.DisplayName}
                  imgClassName={
                    video?.node?.MediaID === "16"
                      ? "web-img"
                      : video?.node?.MediaID === "22" ||
                        video?.node?.MediaID === "23"
                      ? "ios-img"
                      : video?.node?.MediaID === "27"
                      ? "watch-img"
                      : ""
                  }
                />
              ))}
            </div>
          )}
        </Box>
      ) : (
        <Box
          height="18em"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            // alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "22px", paddingLeft: 1 }}
            width="20%"
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            height="80%"
            width="100%"
            sx={{ borderRadius: "5px", marginBottom: 2 }}
          />
        </Box>
      )}
    </Box>
  );
}
