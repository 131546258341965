import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (_, { getState }) => {
    const { user } = getState();

    const response = await axios(
      `${process.env.REACT_APP_SERVER_URL}/api/node_getFunctionalitiesTemplate`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          bearer: user.token,
          "Content-Type": "application/json",
        },
        data: {
          parentid: "0",
          mediaid: "16",
          bankid: "107",
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  lastFetchTime: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { storeData } = categoriesSlice.actions;

export default categoriesSlice.reducer;
