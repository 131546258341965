import { userRoutes } from "../../../Routes/userRoutes";
import { AppAppBar, AppBarContent, MainContainer } from "../../../Theme/styled";
import * as Cookies from "../../../Utilities/cookies";
import logo from "../../../assets/02_FI.png";
import { logout } from "../../../redux/userSlice";
import SearchBar from "../SearchBar/SearchBar";
import "./Navbar.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const routes = userRoutes;

export default function Navbar({ containerWidth }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const toggleTheme = () => {
    const theme = Cookies.get("theme");
    theme === "dark"
      ? Cookies.set("theme", "light", 365)
      : Cookies.set("theme", "dark", 365);
    window.location.reload();
  };

  return (
    <AppAppBar position="static">
      <MainContainer maxWidth={containerWidth}>
        <AppBarContent>
          <Box
            sx={{
              width: "36%",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                height: "60px",
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
              onClick={() => navigate("/")}
            >
              <img alt="logo" src={logo} width={165} />
            </Box>
          </Box>
          <Box
            sx={{
              width: "27%",
              display: "flex",
              justifyContent: "center ",
            }}
          >
            <SearchBar />
          </Box>
          <Box
            sx={{
              width: "36%",
              display: "flex",
              gap: 0.5,
              height: "100%",
              justifyContent: "flex-end",
            }}
          >
            {routes
              ?.filter((i) => i.name !== "hiddenFromNavbar")
              ?.map((i) => (
                <Link
                  key={i.path}
                  className={
                    theme?.palette?.mode == "dark"
                      ? "nav-link"
                      : "nav-link-light"
                  }
                  to={i.path}
                >
                  {i.name}
                </Link>
              ))}
            <Link
              className={
                theme?.palette?.mode == "dark" ? "nav-link" : "nav-link-light"
              }
              onClick={() =>
                (window.location.href = "https://app.fintechinsights.io")
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  gap: 0.5,
                }}
              >
                <img src="/fi-pink.svg" align="absmiddle" height={20} />
                <Typography>Back to FinTech Insights</Typography>
              </Box>
            </Link>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  location.href =
                    "https://app.fintechinsights.io/pages/account/settings";
                }}
              >
                <AccountCircleIcon sx={{ color: "white" }} />
              </IconButton>
              <Typography>{user?.name}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={toggleTheme}>
                {theme?.palette?.mode == "dark" ? (
                  <LightModeIcon />
                ) : (
                  <DarkModeIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            </Box>
          </Box>
        </AppBarContent>
      </MainContainer>
    </AppAppBar>
  );
}
