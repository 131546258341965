import React, { useEffect } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";

const GlobalLoader = ({ isLoading }) => {
  useEffect(() => {
    const body = document.querySelector("body");

    if (isLoading) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isLoading]);

  return (
    <Backdrop
      open={isLoading}
      style={{
        zIndex: 9999,
        backgroundColor: "#000000A3",
        backdropFilter: "blur(4px)",
      }}
    >
      <CircularProgress size={60} />
    </Backdrop>
  );
};

export default GlobalLoader;
