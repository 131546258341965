// Routing
import { createBrowserRouter } from "react-router-dom";

// Layouts
import MainLayout from "../Layouts/MainLayout/MainLayout";

import { authRoutes } from "./authRoutes";
import { userRoutes } from "./userRoutes";

// Logic to set routes per role here
const routes = userRoutes;

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: routes,
  },
]);

export const authRouter = createBrowserRouter([
  {
    path: "/",
    children: authRoutes,
  },
]);
