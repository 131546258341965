import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function MediaFilterList({
  mediaFilter,
  setMediaFilter,
  filters,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const handleMedia = (value) => {
    let temp = [...mediaFilter];
    if (temp.includes(value)) temp = temp.filter((i) => i !== value);
    else temp.push(value);
    dispatch(setMediaFilter(temp));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <List dense disablePadding>
        <ListItem
          onClick={() => setOpen((prv) => !prv)}
          secondaryAction={
            <IconButton edge="end" aria-label="comments">
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton>
            <DevicesOtherIcon />
            <ListItemText
              primary="Channels"
              primaryTypographyProps={{
                fontSize: "19px",
                fontWeight: "bold",
                marginLeft: 1.5,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              dense
              disabled={
                filters
                  ? !filters.some((i) => i.MediaID == "22" || i.MediaID == "23")
                  : false
              }
              onClick={() => handleMedia("iOS")}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    filters
                      ? filters.some(
                          (i) => i.MediaID == "22" || i.MediaID == "23"
                        ) && mediaFilter.includes("iOS")
                      : mediaFilter.includes("iOS")
                  }
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary="iOS"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              dense
              disabled={
                filters ? !filters.some((i) => i.MediaID == "16") : false
              }
              onClick={() => handleMedia("Web")}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    filters
                      ? filters.some((i) => i.MediaID == "16") &&
                        mediaFilter.includes("Web")
                      : mediaFilter.includes("Web")
                  }
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary="Web"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              dense
              disabled={
                filters ? !filters.some((i) => i.MediaID == "27") : false
              }
              onClick={() => handleMedia("Watch")}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    filters
                      ? filters.some((i) => i.MediaID == "27") &&
                        mediaFilter.includes("Watch")
                      : mediaFilter.includes("Watch")
                  }
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary="Watch"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </Box>
  );
}
